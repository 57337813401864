import GoogleClientBase from "./GoogleClientBase";

class Slides extends GoogleClientBase {
  getLink(fileId: string): string {
    return `https://docs.google.com/presentation/d/${fileId}`;
  }

  async create(title: string): Promise<string> {
    const response = await gapi.client.slides.presentations.create({
      resource: {
        title,
      },
    });
  
    const { presentationId } = response.result;

    if (!presentationId) {
      throw new Error("Google Slides Presentation created with empty ID.");
    }

    return presentationId;
  }
};

export default new Slides();
