import { useEffect, useState, useCallback, useMemo } from "react";
import TagModel from "../../models/Tag";
import { Typography, Divider } from "@mui/material";
import { getContentAll, getJWTDecoded, getTagList, useQuery } from "../../helpers/helpers";
import ContentDetails from "../../models/ContentDetails";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { SourceType } from "../../models/enums/SourceType";
import { FileUpload } from "../../services/Google/Drive";
import { useMediaQuery } from "react-responsive";
import { ContentMetadata } from "../shared/ContentMetadata";
import { createSearchParams } from "react-router-dom";
import { getParams } from "../../helpers/discover";
import ItemsListView from "./ItemsListView";
import { SafeYTVideoEditor } from "@pbeejcom/safeyt-video-editor";
import AlertInfo from "../../models/AlertInfo";
import { AlertSnack } from "../util/Alerts";
import Header from "./Header";
import Link from "./Link";
import ContributionMetadata from "./ContributionMetadata";
import Collaborators from "./Collaborators";
import KeyWords from "./Keywords";
import AgeRange from "./AgeRange";
import { useAppContext } from "../AppContext";

export default function Details() {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();
  let { setActiveResource, setActiveResourceName } = useAppContext();

  const resourceId: number = useMemo(() => {
    return id ? +id : 0;
  }, [id])

  let query = useQuery();

  const [details, _setDetails] = useState<ContentDetails>();
  const [keywords, setKeywords] = useState<TagModel[]>([]);
  const [ageRange, setAgeRange] = useState<TagModel[]>([]);
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);
  const isMobilePortrait = useMediaQuery({ query: "(max-width: 550px)" });
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const { showBackArrow = false, alert: hasAlert = false } = location.state || {};

  const setDetails = useCallback((details: ContentDetails) => {
    document.title = details.title;
    _setDetails(details);
  }, []);

  useEffect(() => {
    getTagList().then((data) => {
      setKeywords(data.Keyword);
      setAgeRange(data.AgeRange);
    });
  }, []);

  useEffect(() => {
    if (resourceId) {
      setActiveResource(resourceId);
      getContentAll(resourceId).then((data) => {
        setActiveResourceName(data.title);
        setDetails(data);
      });
    }
  }, [resourceId, setDetails, setActiveResource, setActiveResourceName]);

  useEffect(() => {
    if (hasAlert) {
      setAlert(location.state.alert);
      navigate({ search: `?${createSearchParams({ ...getParams(query) })}` }, { state: { showBackArrow } });
    }
  }, [hasAlert, navigate, query, showBackArrow, location.state]);

  // should this be a hook??
  const setMultiSelectTags = useCallback(
    (tagName, tags: Set<number>): void => {
      navigate(
        {
          search: `?${createSearchParams({
            ...getParams(query),
            [tagName]: Array.from(tags).toString(),
          })}`,
        },
        { state: { showBackArrow } },
      );
    },
    [navigate, query, showBackArrow],
  );

  const isAdult: boolean = getJWTDecoded().isAdult;

  if (!details) {
    return <div>Loading...</div>;
  }

  return (
    <div className={"w-full h-full flex flex-row place-content-center items-center"}>
      <div className={"overflow-x-auto h-full w-full flex flex-col"}>
        <Header resourceId={resourceId} details={details} />
        <div className={"body flex-grow text-left flex p-4 flex-col w-full"}>
          <Typography className={"h-fit"} variant={"h6"}>
            {details.title}
          </Typography>
          <Typography className={"h-fit"} variant="caption" color="text.secondary" align="left" noWrap>
            {details.contentType.id !== 34 && (details.author ? `by ${details.author}` : "")}
          </Typography>
          {details.contentType.id === 34 && details.collectionContent ? <ItemsListView items={details.collectionContent} /> : null}
          {details.sourceType === SourceType.SafeYT && details.sourceId && (
            <div className="w-[90%] h-[400px] mx-auto">
              <SafeYTVideoEditor
                isEditMode={false}
                link={details.link}
                onSafeYTLinkChange={(safeYTLink: string): void => console.warn("SafeYT link changed in edit mode")}
                height={300}
                width={isMobilePortrait ? 350 : 500}
              ></SafeYTVideoEditor>
            </div>
          )}
          {details.sourceType === SourceType.Google && details.typeText === "Video" && details.sourceId && !isMobileDevice && (
            <iframe title={"google-player"} src={FileUpload.getSourceLink(details.sourceId)} className="w-[500px] h-[300px] mx-auto"></iframe>
          )}
          <p style={{ whiteSpace: "pre-line" }} className={"description flex flex-col w-full mt-2 mb-2"}>
            {details.description}
          </p>
          {details.link && <Link link={details.link} typeText={details.typeText} />}
          {details && details.availability && details.availability !== "PBEEJ" && details.contentType.id !== 34 && (
            <span className={"flex flew-row"}>
              <Typography variant={"subtitle1"} className={"h-fit text-left pr-2"}>
                {`Where can I find this?: `}
              </Typography>
              <Typography variant={"subtitle1"} className={"h-fit text-left"}>
                {details.availability}
              </Typography>
            </span>
          )}
          <ContributionMetadata owner={details.owner} createdDate={details?.createdDate} />
        </div>
        <div className={"grow-[2] p-4 flex flex-col"}>
          <ContentMetadata
            isCollection={details.contentType.id === 34}
            free={details.free}
            pbeejOnly={details.pbeejOnly}
            parentResource={details.parentResource && isAdult}
            pgGuidanceReason={details.parentGuidanceReason}
          />
          <Divider orientation={"horizontal"} flexItem />
          <div className={"tags grow-[4] overflow-x-auto flex flex-col"}>
            <Collaborators collaborators={details.collaborators} />
            <KeyWords keywordTags={details.keywordTags} keywords={keywords} setTags={setMultiSelectTags} />
            <AgeRange ageTags={details.ageTags} ageRange={ageRange} />
          </div>
        </div>
      </div>
      {alert && <AlertSnack alertInfo={alert} setAlert={setAlert} />}
    </div>
  );
}
