import {
  Autocomplete,
  TextField,
  InputAdornment
} from '@mui/material';
import Tag from '../../models/Tag';
import Search from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function CategoryType({ tags, selectedTags, setTags }: {
  tags: Tag[],
  selectedTags: Set<number>,
  setTags: Function
}) {

  interface Item {
    label: string,
    key: number,
    isSelected: boolean | false
  }

  const items: Item[] = [];

  tags.forEach((tag) => {
    let item = {
      label: tag.Text,
      key: tag.Id,
      isSelected: false
    }
    if (selectedTags.has(tag.Id)) {
      item.isSelected = true;
    }
    items.push(item)
  })

  function updateTag(tagId: number) {
    let temptags: Set<number> = selectedTags;
    let tag = items.find((item) => item.key === tagId)
    if (tag && tag.isSelected) {
      temptags.delete(tagId);
    } else {
      temptags.add(tagId);
    }
    setTags('type', temptags);
  }

  return (
      <Autocomplete
        options={items}
        renderOption={(props, option) => (
          <div key={option.key.toString()} className={'flex p-2 border-r-4 justify-between cursor-pointer'} onClick={() => updateTag(option.key)}>
            <span>{option.label}</span>
            <span>{(option.isSelected) ? <CheckCircleIcon color="success" sx={{ fontSize: '20px' }}/> : ''}</span>
          </div>
        )}
        selectOnFocus
        handleHomeEndKeys
        // TODO: For autocomplete: add handling for arrow keys and enter key
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position={'start'} className={'flex flex-row place-content-center mb-7'}>
                  <div className={'flex flex-row w-full h-full justify-start content-start'}>
                    <Search />
                    <span>{(selectedTags.size > 0) ? `(${selectedTags.size}) Selected` : ``}</span>
                  </div>
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
  );
}
