import {
  Autocomplete,
  TextField,
  InputAdornment
} from '@mui/material';
import Tag from '../../models/Tag';
import Search from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Keywords({ allTags, selectedTags, setTags }: {
  allTags: Tag[],
  selectedTags: Set<number>,
  setTags: Function
}) {

  function updateTag(tagId: number) {
    if (selectedTags.has(tagId)) {
      selectedTags.delete(tagId);
    } else {
      selectedTags.add(tagId);
    }
    setTags('keywords', selectedTags);
  }

  return (
      <Autocomplete
        options={allTags}
        renderOption={(props: object, option: Tag) => (
          <div key={option.Id} className={'flex justify-between p-2 border-r-4 cursor-pointer'} onClick={() => updateTag(option.Id)}>
            <span>{option.Text}</span>
            <span className={'text-keywords-tag'}>{(selectedTags.has(option.Id)) ? <CheckCircleIcon color="success" sx={{ fontSize: '20px' }}/> : ''}</span>
          </div>
        )}
        getOptionLabel={(option) => option.Text}
        selectOnFocus
        handleHomeEndKeys
        // TODO: For autocomplete: add handling for arrow keys and enter key
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position={'start'} className={'flex flex-row place-content-center mb-7'}>
                  <div className={'flex flex-row w-full h-full justify-start content-start'}>
                    <Search />
                    <span>{(selectedTags.size > 0) ? `(${selectedTags.size}) Selected` : ``}</span>
                  </div>
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
  )
}
