import ArrowBack from "@mui/icons-material/ArrowBack";
import { StatusTag } from "../tags/StatusTag";
import OptionsDropdown from "./OptionsDropdown";
import { itemMenuOptions } from "../../models/enums/ItemMenuOptions";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, Typography, IconButton, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getParams } from "../../helpers/discover";
import { useQuery } from "../../helpers/helpers";
import getContentTypeIcon from "../content/ContentIcon";
import ContentDetails from "../../models/ContentDetails";
import { useAppContext } from "../AppContext";


interface HeaderProps {
    resourceId: number,
    details: ContentDetails
}

export default function Header({ resourceId, details }: HeaderProps) {
    const navigate = useNavigate();
    const location = useLocation();
    let { setAddingToProject } = useAppContext();
    const [edit, setEdit] = useState(false);
    const [editLink, setEditLink] = useState(false);
    let query = useQuery();
    const { showBackArrow = false } = location.state || {};

    useEffect(() => {
        if (!edit && editLink) {
            setEditLink(false);
        }
    }, [edit, editLink]);

    const handleModeSelected = useCallback(
        (newMode: itemMenuOptions) => {
            const onEdit = () => {
                setEdit(true);
                navigate(
                    {
                        pathname: `/details/${resourceId}/edit`,
                        search: `?${createSearchParams({
                            ...getParams(query),
                        })}`,
                    },
                    {
                        state: { showBackArrow },
                    },
                );
            };

            const onAddToProject = () => {
                setAddingToProject(true);
            };

            const onClone = () => {
                navigate({
                    pathname: `/details/${resourceId}/clone`,
                    search: `?${createSearchParams({
                        ...getParams(query),
                    })}`,
                });
            };

            const onCopyLink = () => {
                navigator.clipboard.writeText(window.location.href);
            };

            switch (newMode) {
                case itemMenuOptions.addToProject:
                    onAddToProject();
                    break;
                case itemMenuOptions.clone:
                    onClone();
                    break;
                case itemMenuOptions.copyLink:
                    onCopyLink();
                    break;
                case itemMenuOptions.edit:
                    onEdit();
                    break;
            }
        },
        [navigate, query, resourceId, setAddingToProject, showBackArrow],
    );

    const navigateToDiscover = () => {
        navigate({
            pathname: `/`,
            search: `?${createSearchParams({
                ...getParams(query),
            })}`,
        });
    };

    return (
        <AppBar position={"sticky"} sx={{ backgroundColor: "white", color: "black" }}>
            <Toolbar>
                <Stack spacing={1} direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                    {showBackArrow ? (
                        <IconButton onClick={() => window.history.back()} size="small" edge="start" color="inherit" sx={{ margin: "auto 0 !important" }}>
                            <ArrowBack />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => navigateToDiscover()} size="small" edge="start" color="inherit" sx={{ margin: "auto 0 !important" }}>
                            <CloseIcon />
                        </IconButton>
                    )}
                    <Stack spacing={0} alignItems={"center"} direction={"column"}>
                        <Stack spacing={1} direction={"row"}>
                            {getContentTypeIcon(details.typeText)}
                            <Typography variant={"h6"} component={"h6"}>
                                {details.typeText}
                            </Typography>
                        </Stack>
                        <StatusTag text={details.status} sx={{ marginTop: "0px !important" }} />
                    </Stack>
                    <div style={{ margin: "auto 0" }}>
                        <OptionsDropdown onModeSelected={handleModeSelected} details={details} />
                    </div>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}