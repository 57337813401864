import Typography from "@mui/material/Typography";
import ContentDetails from "../../models/ContentDetails";
import TagModel from "../../models/Tag";
import Tag from "../tags/Tag";

interface AgeRangeProps {
    ageTags: ContentDetails["ageTags"],
    ageRange: TagModel[]
}

export default function AgeRange({ ageTags, ageRange }: AgeRangeProps) {

    return (
        <span className={"agerange flex flex-col"}>
            <Typography className={"text-agerange-tag text-left"} variant={"subtitle1"} component={"div"}>
                Age Range:
            </Typography>
            <div className={"flex flex-wrap"}>
                {ageTags.map((ageTag) => {
                    return (
                        <Tag
                            editable={false}
                            key={ageTag.id}
                            tag={ageRange.find((element) => element.Id === ageTag.id)}
                            deleteTag={(): null => null}
                        />
                    );
                })}
            </div>
        </span>
    );
}