import { useEffect, useMemo, useState } from "react";
import {
    FormControl,
    Autocomplete,
    Typography,
    Checkbox,
    FormHelperText,
    FormControlLabel,
    TextField,
    createFilterOptions,
    Stack
} from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import TagModel from "../../models/Tag";
import Tag from "../tags/Tag";
import { AddFormValues } from "./AddContent";

interface KeywordTag {
    id: number;
    promptText?: string;
    label: string;
    isNewTag: boolean;
}

const filter = createFilterOptions<KeywordTag>();

export default function KeywordsAndAgeRanges({
    form,
    setFormError,
    keywords,
    ageRanges,
}: {
    form: UseFormReturn<AddFormValues>,
    setFormError: React.Dispatch<React.SetStateAction<boolean>>,
    keywords: TagModel[],
    ageRanges: TagModel[],
}) {
    const [autocompleteValue, setAutocompleteValue] = useState<KeywordTag[]>([]);
    const [keywordValues, setKeywordValues] = useState<KeywordTag[]>(Array.from(form.getValues('selectedKeywords')).map(keyword => ({
        id: keyword.Id,
        label: keyword.Text,
        isNewTag: false,
    })));
    const [selectedAgeRangesCount, setSelectedAgeRangesCount] = useState<number>(form.getValues("selectedAgeRanges").size);

    const keywordOptions: KeywordTag[] = keywords.filter(keyword => {
        return !keywordValues.some(v => v.id === keyword.Id);
    }).map(keyword => {
        return {
            id: keyword.Id,
            label: keyword.Text,
            isNewTag: false,
        }
    })

    // function isAllChecked(selectedAgeRanges: Set<TagModel>, currentValue: any): boolean {
    //     return selectedAgeRanges && selectedAgeRanges.size === ageRanges.length;
    // }

    function randomId(): number {
        return Math.floor(Math.random() * 1000000000);
    }

    const invalidSelectedAgeRanges = useMemo(() => selectedAgeRangesCount === 0, [selectedAgeRangesCount]);

    useEffect(() => {
        setFormError(invalidSelectedAgeRanges)
    }, [setFormError, invalidSelectedAgeRanges]);

    return (
        <>
            <Controller
                control={form.control}
                name="selectedKeywords"
                rules={{ required: true, min: 1 }}
                render={({
                    field: { onChange },
                    fieldState: { error },
                }) => (
                    <FormControl error={!!error} variant="standard">
                        <Autocomplete
                            value={autocompleteValue}
                            multiple
                            onChange={(_event, [newValue]) => {
                                let tag: KeywordTag;

                                if (typeof newValue === 'string') {
                                    tag = {
                                        id: randomId(),
                                        label: newValue,
                                        isNewTag: true
                                    }
                                } else {
                                    tag = newValue;
                                }

                                const newKeywords = form.getValues('newKeywords') || [];
                                const selectedKeywords = form.getValues('selectedKeywords');
                                if (tag.isNewTag) {
                                    newKeywords.push(tag.label)
                                } else {
                                    selectedKeywords.add({
                                        Id: tag.id,
                                        Text: tag.label,
                                        TagType: 'Keyword'
                                    });
                                }
                                form.setValue('newKeywords', newKeywords);
                                form.setValue('selectedKeywords', selectedKeywords);
                                setKeywordValues(keywordValues.concat(tag))
                                setAutocompleteValue([]);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue.toLocaleLowerCase() === option.label.toLocaleLowerCase());
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        id: randomId(),
                                        promptText: `Create New Keyword: "${inputValue}"`,
                                        label: inputValue,
                                        isNewTag: true,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={keywordOptions}
                            renderOption={(props, option) => <li {...props}>{option.isNewTag ? option.promptText || '' : option.label}</li>}
                            sx={{ width: '100%', marginBottom: '5px' }}
                            freeSolo
                            renderInput={(params) => {
                                return (
                                    <TextField {...params} label="Keywords" variant="standard" />
                                )
                            }}
                        />

                        <Stack direction={'row'} flexWrap={'wrap'}>
                            {keywordValues.map((value, index) => (
                                <Tag
                                    key={value.id}
                                    editable={true}
                                    tag={{
                                        TagType: 'Keyword',
                                        Id: value.id,
                                        Text: value.label
                                    }}
                                    deleteTag={() => {
                                        setKeywordValues(keywordValues.filter(v => v.id !== value.id));
                                        form.setValue('selectedKeywords', new Set(keywordValues.filter(v => v.id !== value.id).map(kv => ({
                                            Id: kv.id,
                                            Text: kv.label,
                                            TagType: 'Keyword'
                                        }))));
                                    }}
                                />
                            ))}
                        </Stack>
                        <FormHelperText id="title">{error ? 'Please select keywords for searching' : ' '}</FormHelperText>
                    </FormControl>
                )}
            />

            <Typography variant="h6" component="h6">Ages most suited for</Typography>
            <div className='flex flex-col'>
                {/* <Controller
                    control={form.control}
                    name="selectedAgeRanges"
                    render={({
                        field: { onChange, value }
                    }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked={isAllChecked(form.getValues('selectedAgeRanges'), value)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            onChange(ageRanges.reduce((previousValue, currentValue) => {
                                                return {
                                                  ...previousValue,
                                                  [currentValue.Id]: true
                                                }
                                              }, {}));
                                        } else {
                                            onChange({});
                                        }
                                    }}
                                />
                            }
                            label="Everyone"
                        />
                    )}
                /> */}

                <div className='flex flex-wrap'>
                    <Controller
                        control={form.control}
                        name={'selectedAgeRanges'}
                        rules={{ required: true, validate: () => !invalidSelectedAgeRanges }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => {
                            return (
                            <FormControl error={!!error} variant="standard">
                                <div className="flex flex-row flex-wrap">
                                    {ageRanges.map(ageRange => {
                                        return <FormControlLabel
                                            key={ageRange.Id}
                                            control={
                                                <Checkbox
                                                    checked={Array.from(form.getValues('selectedAgeRanges')).some(v => v === ageRange.Id)}
                                                    onChange={(e) => {
                                                        // const valueCopy: any = { ...value };
                                                        if (e.target.checked) {
                                                            const formerValue = form.getValues('selectedAgeRanges')
                                                            formerValue.add(ageRange.Id);
                                                            setSelectedAgeRangesCount(formerValue.size);
                                                            form.setValue('selectedAgeRanges', formerValue);
                                                            // valueCopy[ageRange.Id] = true;
                                                        } else {
                                                            const formerValue = form.getValues('selectedAgeRanges')
                                                            formerValue.delete(ageRange.Id);
                                                            setSelectedAgeRangesCount(formerValue.size);
                                                            form.setValue('selectedAgeRanges', formerValue);
                                                            // delete valueCopy[ageRange.Id];
                                                        }
                                                        // onChange(valueCopy);
                                                    }}
                                                    value={ageRange.Id}
                                                />
                                            }
                                            label={ageRange.Text}
                                        />
                                    })}
                                </div>
                                {invalidSelectedAgeRanges &&
                                    <FormHelperText id="invalidSelectedAgeRanges" style={{ color: "red" }}>Please select appropriate age ranges</FormHelperText>
                                }
                            </FormControl>
                        )}}
                    />
                </div>
            </div>
        </>
    )
}
