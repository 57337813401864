export default class FileRead {
  isCanceled: boolean;

  constructor() {
    this.isCanceled = false;
  }

  async read(file: File, setProgress?: (progress: number) => void) {
    const CHUNK_SIZE = 99_999_999; // must be divisible by 3 (since base64 encoded)
    const numReadChunks = Math.ceil(file.size / CHUNK_SIZE);
    const chunkResults: Array<string | ArrayBuffer | null | undefined> = [];
    for (let i = 0; i < numReadChunks; i++) {
      if (this.isCanceled) {
        throw new Error('Read canceled by user.');
      }
      setProgress?.((i / numReadChunks) * 100);
      const chunkStart = i * CHUNK_SIZE;
      const nextChunkStart = (i + 1) * CHUNK_SIZE;
      const chunkEnd = Math.min(nextChunkStart, file.size);
      const chunk = file.slice(chunkStart, chunkEnd);
      chunkResults.push(await new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onloadend = (event) => {
          if (event.target && event.target.result) {
            const [, content] = (event.target.result as string).split(",");
            if (content) {
              res(content);
            } else {
              rej(`Unexpected file read result: ${event.target.result}`);
              console.error(event.target.result);
            }
          } else {
            rej(`Unexpected file read event: ${event}`);
            console.error(event);
          }
        };
        reader.onerror = (event) => {
          rej(event.target?.error);
          console.error(event);
        };
        reader.readAsDataURL(chunk);
      }));
    }
    return chunkResults as string[];
  }

  cancel() {
    this.isCanceled = true;
  }
}
