import { Typography } from "@mui/material";
import moment from "moment";

interface ContributionMetadataProps {
    owner: string,
    createdDate: string
}

export default function ContributionMetadata({owner, createdDate}: ContributionMetadataProps) {
    const displayDate = moment(Date.parse((createdDate) || "")).format("MM/DD/YYYY");

    return (
        <>
            <Typography variant={"caption"} className={"text-right"}>
                {`Added by ${owner}`}
            </Typography>
            <Typography variant={"caption"} className={"text-right"}>
                {`on ${displayDate}`}
            </Typography>
        </>
    );
}