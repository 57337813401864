interface FileMinimalChunk {
  start: number,
  size: number,
};

interface FileRequestChunk {
  start: number,
  size: number,
};

export interface FileRequest {
  contentType: string,
  contentLength: number,
  contentRange: string,
  end: number,
  body: string,
  retries: number,
};

export enum FileUploadStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  INITIALIZED = 'INITIALIZED',
  FAILED_TO_INITIALIZE = 'FAILED_TO_INITIALIZE',
  PAUSED = 'PAUSED',
  PAUSED_BY_ERROR = 'PAUSED_BY_ERROR',
  UNINITIALIZED = 'UNINITIALIZED',
};

const REQUEST_RETRIES = 2;

export class FileUpload {
  status: FileUploadStatus;
  file: File;
  uploadId: string | null;
  requests: FileRequest[];
  completedRequestCount: number;
  progress: number;
  fileId: string | null;
  error: any;

  constructor(file: File) {
    this.status = FileUploadStatus.UNINITIALIZED;
    this.file = file;
    this.uploadId = null;
    this.requests = [];
    this.completedRequestCount = 0;
    this.progress = 0;
    this.fileId = null;
    this.error = null;
  }

  static getLink(fileId: string): string {
    return `https://drive.google.com/file/d/${fileId}`
  }

  // returns link to the source media - not a Drive page
  static getSourceLink(fileId: string): string {
    // return `https://drive.google.com/uc?id=${fileId}&export=download`;
    return `https://drive.google.com/file/d/${fileId}/preview`;
  }

  async uploadFile(contentsBase64: string[], progressCallback: (fileUpload: FileUpload) => void) {
    await this.initiateFileUpload(contentsBase64, progressCallback);

    if (![
      FileUploadStatus.CANCELED,
      FileUploadStatus.FAILED_TO_INITIALIZE,
      FileUploadStatus.UNINITIALIZED,
    ].includes(this.status)) {
      await this.uploadFileData(progressCallback);
    }
  }

  pauseFileUpload(progressCallback: (fileUpload: FileUpload) => void) {
    this.status = FileUploadStatus.PAUSED;
    progressCallback(this);
    return;
  }

  async resumeFileUpload(progressCallback: (fileUpload: FileUpload) => void) {
    await this.uploadFileData(progressCallback);
  }

  cancelFileUpload(progressCallback: (fileUpload: FileUpload) => void) {
    this.status = FileUploadStatus.CANCELED;
    progressCallback(this);
    return;
  }

  private async initiateFileUpload(contentsBase64: string[], progressCallback: (fileUpload: FileUpload) => void) {
    try {
      const metadata = {
        name: this.file.name,
        mimeType: this.file.type,
      };
      const metadataResponse = await gapi.client.request({
        method: 'POST',
        path: '/upload/drive/v3/files',
        params: {
          uploadType: 'resumable',
          supportsAllDrives: true,
        },
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Length': new Blob([JSON.stringify(metadata)]).size,
          'X-Upload-Content-Type': this.file.type,
          'X-Upload-Content-Length': this.file.size,
        },
        body: metadata,
      });

      const location = metadataResponse.headers?.location;
      if (!location) {
        this.status = FileUploadStatus.FAILED_TO_INITIALIZE;
        this.error = new Error("Google Drive resumable file upload is not available.");
        progressCallback(this);
        return;
      }

      const uploadId = new URL(location).searchParams.get('upload_id');
      if (!uploadId) {
        this.status = FileUploadStatus.FAILED_TO_INITIALIZE;
        this.error = new Error("Google Drive resumable file upload initiation failed.");
        progressCallback(this);
        return;
      }

      this.status = FileUploadStatus.INITIALIZED;
      this.uploadId = uploadId;
      this.requests = this.breakFileContentsIntoRequests(contentsBase64);
      progressCallback(this);
      return;
    } catch (err) {
      this.status = FileUploadStatus.FAILED_TO_INITIALIZE;
      this.error = err;
      progressCallback(this);
      return;
    }
  }

  private async uploadFileData(progressCallback: (fileUpload: FileUpload) => void) {
    this.status = FileUploadStatus.IN_PROGRESS;

    // Use for-loop (not forEach) to enable async/await to work.
    for (let i = this.completedRequestCount; i < this.requests.length; i++) {
      if ([
        FileUploadStatus.PAUSED,
        FileUploadStatus.CANCELED,
      ].includes(this.status)) {
        progressCallback(this);
        return;
      }

      const request = this.requests[i];

      try {
        console.log(`Content-Length: ${request.contentLength}`);
        console.log(`Content-Range: ${request.contentRange}`);

        const completionResponse = await gapi.client.request({
          method: 'PUT',
          path: '/upload/drive/v3/files',
          params: {
            uploadType: 'resumable',
            upload_id: this.uploadId,
          },
          headers: {
            'Content-Type': request.contentType,
            'Content-Length': request.contentLength,
            'Content-Range': request.contentRange,
            'Content-Encoding': 'base64',
          },
          body: request.body,
        });

        // The following code is only reached upon 2xx response, which indicates file upload is fully complete.

        const { id } = completionResponse.result;
        if (!id) {
          this.status = FileUploadStatus.COMPLETED_WITH_ERROR;
          this.error = new Error("Google Drive file created with empty ID.");
          progressCallback(this);
          return;
        }
        console.log(`Generated file with id: ${id}`);

        this.status = FileUploadStatus.COMPLETED;
        this.completedRequestCount++;
        this.progress = 1;
        this.fileId = id;
        progressCallback(this);
        return;

      } catch (err) {
        const errorResponse = err as gapi.client.Response<any>;
        // 308 is returned when chunk uploaded successfully but file requires more uploads to complete
        if (errorResponse.status === 308) {
          this.completedRequestCount++;
          this.progress = request.end / this.file.size;
          progressCallback(this);
        } else {
          console.error(`File upload failed on: ${request.contentRange} with error:`);
          console.error(errorResponse);

          if (request.retries > 0) {
            console.error(`Will retry upload of: ${request.contentRange}`);
            request.retries--;
            i--; // retry this request
          } else {
            this.status = FileUploadStatus.PAUSED_BY_ERROR;
            request.retries = REQUEST_RETRIES; // allow user to resume file upload with full number of retries for failed request
            this.error = err;
            progressCallback(this);
            return;
          }
        }
      }
    }

    this.status = FileUploadStatus.COMPLETED_WITH_ERROR;
    this.error = new Error(`Found no data to upload for file ${this.file.name} (${this.file.size} Bytes).`);
    progressCallback(this);
    return;
  }

  private breakFileContentsIntoRequests(contentsBase64: string[]): FileRequest[] {
    const requestChunks = this.breakFileIntoRequestChunks();

    return requestChunks.map((requestChunk) => {
      const end = requestChunk.start + requestChunk.size;
      const contentRange = `bytes ${requestChunk.start}-${end - 1}/${this.file.size}`;
      // start is always at chunk boundary (and thus divisible by 3).
      const base64Start = this.rawIndexToBase64Index(requestChunk.start);
      // For last chunk, end might not be divisible by 3 (if chunk is partial).
      // After converting to base64, end might not be divisible by 4.
      // FileReader base64 string pads with 1 or 2 "=" chars to reach multiple of 4 ASCII chars.
      const base64End = this.ceilBase64IndexToMultipleOf4Chars(this.rawIndexToBase64Index(end));
      const body = this.sliceAmongStrings(contentsBase64, base64Start, base64End);
      return {
        contentType: this.file.type,
        contentLength: requestChunk.size,
        contentRange,
        end,
        body,
        retries: REQUEST_RETRIES,
      };
    });
  }

  private breakFileIntoRequestChunks(): FileRequestChunk[] {
    // TODO fine-tune these
    const MINIMAL_CHUNK_THRESHOLD_FOR_CHUNKING_REQUEST = 10;
    const MAXIMUM_REQUEST_COUNT = 100;

    const minimalChunks = this.breakFileIntoMinimalChunks();
    let requestChunks: FileRequestChunk[] = [];

    if (minimalChunks.length > MINIMAL_CHUNK_THRESHOLD_FOR_CHUNKING_REQUEST) {
      const chunkCountPerRequest = Math.ceil(minimalChunks.length / MAXIMUM_REQUEST_COUNT);
      requestChunks = minimalChunks.reduce<FileRequestChunk[]>((accRequests, curChunk, i) => {
        if (i % chunkCountPerRequest === 0) {
          const curRequest = {
            start: curChunk.start,
            size: curChunk.size,
          };
          accRequests.push(curRequest);
        } else {
          const currentRequest = accRequests[accRequests.length - 1];
          currentRequest.size += curChunk.size;
        }

        return accRequests;
      }, []);
    } else {
      requestChunks = minimalChunks.map((chunk) => ({
        start: chunk.start,
        size: chunk.size,
      }));
    }

    return requestChunks;
  }

  private breakFileIntoMinimalChunks(): FileMinimalChunk[] {
    const chunks: FileMinimalChunk[] = [];

    // Atomic chunk size for Google Drive resumable file upload is 256 KB.
    // Practically, chunk size should also be divisible by 3:
    // This enables conversion of a set of chunks of raw data to a set of complete ASCII chars of base64-encoded data,
    // since every 3 bytes of raw data converts to 4 ASCII chars of base64-encoded data (each char holding 6 bits).
    const chunkSize = 3 * 256 * 1024;

    const remainderChunkLength = this.file.size % chunkSize;
    const fullyChunkedLength = this.file.size - remainderChunkLength;
    const fullChunksCount = fullyChunkedLength / chunkSize;

    for (let i = 0; i < fullChunksCount; i++) {
      chunks.push({
        start: i * chunkSize,
        size: chunkSize,
      });
    }

    chunks.push({
      start: fullyChunkedLength,
      size: remainderChunkLength,
    });

    return chunks;
  }

  private rawIndexToBase64Index(index: number): number {
    return 4 * (index / 3);
  }

  private ceilBase64IndexToMultipleOf4Chars(index: number): number {
    return 4 * Math.ceil(index / 4);
  }

  private sliceAmongStrings(strings: string[], globalStart: number, globalEnd: number): string {
    const stringLengths = strings.map((str) => str.length);
    const [startStringNumber, startIndex] = this.positionGlobalIndexAmongItemsWithLengths(stringLengths, globalStart);
    const [endStringNumber, endIndex] = this.positionGlobalIndexAmongItemsWithLengths(stringLengths, globalEnd);
    const startString = strings[startStringNumber];
    const endString = strings[endStringNumber];
    if (startStringNumber === endStringNumber) {
      return startString.slice(startIndex, endIndex);
    } else {
      const stringsInBounds: string[] = [];
      stringsInBounds.push(startString.slice(startIndex));
      for (let i = startStringNumber + 1; i < endStringNumber; i++) {
        stringsInBounds.push(strings[i]);
      }
      stringsInBounds.push(endString.slice(0, endIndex));
      return stringsInBounds.join("");
    }
  }

  private positionGlobalIndexAmongItemsWithLengths(itemLengths: number[], globalIndex: number): [number, number] {
    let seekIndex = 0;
    for (let i = 0; i < itemLengths.length; i++) {
      const itemLength = itemLengths[i];
      if (seekIndex <= globalIndex && globalIndex <= (seekIndex + itemLength)) {
        return [i, globalIndex - seekIndex];
      }
      seekIndex += itemLength;
    }
    throw new Error(`Could not position global index = ${globalIndex} on an item / index among items with lengths: [${itemLengths.join(", ")}]`);
  }
};
