import {FormGroup, FormControlLabel, Checkbox} from '@mui/material';
import { getJWTDecoded } from '../../helpers/helpers';

interface AvailabilityFiltersProps {
    freeFlag: boolean,
    pbeejOnlyFlag: boolean,
    parentResourceFlag: boolean,
    setBooleanFlags: any
}

export default function AvailabilityFilters({
      freeFlag,
      pbeejOnlyFlag,
      parentResourceFlag,
      setBooleanFlags}: AvailabilityFiltersProps ) {
    const isAdult: boolean = getJWTDecoded().isAdult;

    const setFilter = (filterName: string, flagOn: boolean) => {
      let filters = [{
        flagName: filterName,
        flagOn: flagOn
      }]
      setBooleanFlags(filters);
    }

    return (
        <FormGroup sx={{display: 'inline-flex', flexDirection: 'row'}}>
            <FormControlLabel control={<Checkbox checked={freeFlag} onClick={() => setFilter('freeFlag', !freeFlag)}/>} label={"Free"}/>
            <FormControlLabel control={<Checkbox checked={pbeejOnlyFlag} onClick={() => setFilter('pbeejOnlyFlag', !pbeejOnlyFlag)}/>} label="PBEEJ Only"/>
            {isAdult &&
                <FormControlLabel control={<Checkbox checked={parentResourceFlag} onClick={() => setFilter('parentResourceFlag', !parentResourceFlag)}/>}label="Parental Guidance"/>
            }
        </FormGroup>
    )
}
