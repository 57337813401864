import { Link } from '@mui/material';
import openLinkInNewTab from "../../helpers/openLinkInNewTab";

export default function ExternalLink({
  link,
  text,
  useEllipsisForOverflow = false,
} : {
  link: string,
  text: string,
  useEllipsisForOverflow?: boolean,
}) {
  return (
    <Link
      style={{
        cursor: 'pointer',
        ...(!useEllipsisForOverflow && { 'overflowWrap': 'anywhere' })
      }}
      className={useEllipsisForOverflow ? 'min-h-[20px] whitespace-nowrap overflow-hidden overflow-ellipsis' : ''}
      onClick={() => openLinkInNewTab(link, true)}>
      {text}
    </Link>
  );
};
