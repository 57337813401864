import {
    InputLabel,
    FormControl,
    Input,
    Button,
    Collapse,
    FormHelperText,
    LinearProgress,
    Stack,
    Typography,
    TextField,
    IconButton
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForever from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import {useCallback, useRef, useState} from 'react';
import { UseFormReturn, Controller } from "react-hook-form";
import FileRead from '../../services/Browser/FileRead';
import { YouTube, SafeYTVideoEditor } from '@pbeejcom/safeyt-video-editor'
import { FileUpload, FileUploadStatus } from '../../services/Google/Drive';
import { SourceType } from '../../models/enums/SourceType';
import { logFileUploadError } from '../../services/Google/Logging';
import { AddFormValues } from './AddContent';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export interface BasicInfoFormValues {
    contentType: number;
    link: string;
    title: string;
    description: string;
    sourceType: string | null;
    sourceId: string | null;
};

export default function LinkInput({
    form,
    setValue,
    editing,
    sourceId,
    setUploadInProgress,
    deleteFile,
}: {
    form: UseFormReturn<AddFormValues>,
    setValue: any,
    editing: boolean,
    sourceId: string,
    setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    deleteFile: () => void
}) {
    const [editingVideo, setEditingVideo] = useState(false);
    const [link, setLink] = useState(form.getValues('link') ||  "");
    const [fileRead, setFileRead] = useState<FileRead | null>(null);
    const [readProgress, setReadProgress] = useState(0);
    const [fileUpload, setFileUpload] = useState<FileUpload | null>(null);
    const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>(FileUploadStatus.UNINITIALIZED);
    const [requestingFileUploadStatusChange, setRequestingFileUploadStatusChange] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [linkDisabled, setLinkDisabled] = useState(true);
    const [originalLink, setOriginalLink] = useState(form.getValues('link'))

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const toggleEditVideo = () => {
        setEditingVideo(!editingVideo);
    }

    const isValidUrl = (url: string): boolean => {
      const rx = /^((https?|ftp|smtp):\/\/)?[a-zA-Z0-9\-]+(\.[a-z0-9A-Z\-]+)+(\/.*)*$/;
      return rx.test(url);
    }

    const onEditLinkInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = event.target.value.replace(/ /g, ''); // trim whitespace

      if (YouTube.isValidYouTubeLink(newValue)) {
          YouTube.getVideoData(newValue).then(response => {
              form.setValue("title", response.data.title)
              form.setFocus("title")
          });
      } else {
        form.setValue("link", newValue);
      }

      setLink(newValue);
    }

    const handleSafeYTLinkChange = useCallback((safeYTLink: string): void => {
      if (safeYTLink !== '' && (YouTube.isValidSafeYTLink(link) || YouTube.isValidYouTubeLink(link))) {
        form.setValue("link", safeYTLink)
        form.setValue("sourceId", YouTube.extractSafeYtEncodedInformation(safeYTLink))
        form.setValue("sourceType", SourceType.SafeYT)
      }
    }, [form, link]);

    const cancelFileRead = useCallback(() => {
        fileRead?.cancel();
        setFileRead(null);
        setUploadInProgress(false);
        setLink(originalLink);
    }, [fileRead]);

    const handleFileUploadProgress = useCallback((fileUpload: FileUpload) => {
        if (fileUpload.error) {
            logFileUploadError(fileUpload);
        }

        // Common operations that should run in any case.
        setFileUpload(fileUpload);
        setFileUploadStatus(fileUpload.status);
        setRequestingFileUploadStatusChange(false);
        setUploadProgress(100 * fileUpload.progress);

        switch (fileUpload.status) {
            case FileUploadStatus.COMPLETED:
                setFileUpload(null);
                setUploadedFileName(fileUpload.file.name);
                form.setValue('link', FileUpload.getLink(fileUpload.fileId!));
                form.setValue('sourceId', fileUpload.fileId);
                form.setValue('sourceType', SourceType.Google);
                setUploadInProgress(false);
                break;
            case FileUploadStatus.CANCELED:
                setFileUpload(null);
                setUploadProgress(0);
                setUploadInProgress(false);
                setLink(originalLink);
                break;
            case FileUploadStatus.FAILED_TO_INITIALIZE:
            case FileUploadStatus.COMPLETED_WITH_ERROR:
                setFileUpload(null);
                setUploadProgress(0);
                setUploadInProgress(false);
                alert('Sorry. Something went wrong. Please refresh the page and try that again. If you still encounter difficulties, please submit feedback. Thank you!');
                break;
        }
    }, [form]);

    const handlePickFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        cancelLinkEdit();
        setLink('');
        setUploadInProgress(true);
        const files = event.target.files;
        if (files) {
            const file = files[0];

            const fileRead = new FileRead();
            setFileRead(fileRead);
            fileRead.read(file, setReadProgress)
                .then((contentsBase64) => {
                    setFileRead(null);
                    const fileUpload = new FileUpload(file);
                    setFileUpload(fileUpload);
                    setRequestingFileUploadStatusChange(true);
                    fileUpload.uploadFile(contentsBase64, handleFileUploadProgress);
                })
                .catch((error) => {
                    logFileUploadError({
                        status: 'FileRead / FileUpload Error',
                        fileName: file.name,
                        error: {
                            result: error,
                            body: JSON.stringify(error),
                        },
                        fileMetadata: {
                            type: file.type,
                            size: file.size,
                        },
                    });
                });
        }
    }, [handleFileUploadProgress]);

    const isLinkValid = link === '' || isValidUrl(link);

    const disabledLink = editing && linkDisabled;

    const cancelLinkEdit = () => {
        setLinkDisabled(true);
        setLink(originalLink)
    }

    const saveLinkEdit = () => {
        setLinkDisabled(true);
        setOriginalLink(link);
    }

    const isYouTubeChannelLink = (link: string) => {
      // Hopefully it catches anything other than individual videos since those are automatically converted to safeyt videos
      return !!link.match(/^(https?:\/\/)?(www\.)?youtube\.com\/(channel\/[A-Za-z0-9_-]+|c\/[A-Za-z0-9_-]+|user\/[A-Za-z0-9_-]+|playlist\?list=[A-Za-z0-9_-]+|@?[A-Za-z0-9_-]+)$/);
    }

    const deleteNewlyUploadedFile = () => {
        setFileRead(null);
        setUploadedFileName('');
        deleteFile();
    }

    return (
        <>
            {fileUpload || !!uploadedFileName
                ? !!uploadedFileName
                ? <span>
                  <span className='text-success'><CheckCircleOutlineIcon /> {uploadedFileName} </span>
                    <IconButton
                      size='small'
                      edge='start'
                      onClick={() => deleteNewlyUploadedFile()}>
                      <DeleteForever/>
                    </IconButton>
                  </span>
                    : fileUpload && <div className={'mt-2'}>
                        <LinearProgress variant='determinate' value={uploadProgress} />
                        <Typography className={'h-fit pt-1 pb-1 italic'} variant={'subtitle2'}>Uploading File...</Typography>
                        {fileUploadStatus === FileUploadStatus.PAUSED_BY_ERROR && <span className='text-error'><ErrorOutline /> Error uploading {fileUpload.file.name} </span>}
                        {requestingFileUploadStatusChange ? <div className={'h-8'} /> :
                            <Stack direction='row' spacing={2} justifyContent='center'>
                                <Button variant='outlined' size={'small'} style={{ color: 'black', borderColor: 'black' }} startIcon={<DeleteForever/>} onClick={() => {
                                    setRequestingFileUploadStatusChange(true);
                                    fileUpload.cancelFileUpload(handleFileUploadProgress);
                                }}>Cancel</Button>
                                {![FileUploadStatus.PAUSED, FileUploadStatus.PAUSED_BY_ERROR].includes(fileUploadStatus) && <Button variant='contained' size='small' startIcon={<PauseIcon />} onClick={() => {
                                    setRequestingFileUploadStatusChange(true);
                                    fileUpload.pauseFileUpload(handleFileUploadProgress);
                                }}>Pause</Button>}
                                {(fileUploadStatus === FileUploadStatus.PAUSED) && <Button variant='contained' size='small' color='success' startIcon={<PlayIcon />} onClick={() => {
                                    setRequestingFileUploadStatusChange(true);
                                    fileUpload.resumeFileUpload(handleFileUploadProgress);
                                }}>Resume</Button>}
                                {(fileUploadStatus === FileUploadStatus.PAUSED_BY_ERROR) && <Button variant='contained' size='small' color='success' startIcon={<PlayIcon />} onClick={() => {
                                    setRequestingFileUploadStatusChange(true);
                                    fileUpload.resumeFileUpload(handleFileUploadProgress);
                                }}>Try Again</Button>}
                            </Stack>
                        }
                    </div>
                : fileRead ? <div className={'mt-2'}>
                    <LinearProgress variant='determinate' value={readProgress} />
                    <Typography className={'h-fit pt-1 pb-1 italic'} variant={'subtitle2'}>Reading File...</Typography>
                    <div>
                        <Button variant='outlined' size='small' style={{ color: 'black', borderColor: 'black' }} startIcon={<CancelIcon />} onClick={cancelFileRead}>Cancel</Button>
                    </div>
                </div>
                    : <Stack direction={'column'}>
                        {!YouTube.isValidSafeYTLink(link) ? <Stack direction={'row'}>
                            <FormControl variant="standard" className='flex-auto' error={!isLinkValid}>
                                <TextField
                                    id="link"
                                    variant='standard'
                                    label={'Link'}
                                    aria-describedby="link"
                                    value={link}
                                    onChange={onEditLinkInput}
                                    disabled={disabledLink}
                                />
                                <FormHelperText id="title">{isYouTubeChannelLink(link) ? 'Please mark this YouTube channel as Parental Guidance Needed below. Or you can add the channel\'s videos individually to a PBEEJ collection' : !isLinkValid ? 'Invalid link' : ' '}</FormHelperText>
                            </FormControl>
                            {disabledLink &&
                                <IconButton sx={{ height: 'fit-content', margin: 'auto' }} aria-label={'edit'} onClick={() => setLinkDisabled(false)}>
                                    <EditIcon />
                                </IconButton>
                            }
                            {(editing && !disabledLink) &&
                                <>
                                    <IconButton onClick={cancelLinkEdit}>
                                        <ClearIcon />
                                    </IconButton>
                                    <IconButton disabled={!isLinkValid} onClick={saveLinkEdit}>
                                        <CheckIcon />
                                    </IconButton>
                                </>
                            }
                        </Stack> : null }

                        <div className="flex justify-center">

                            <Button component="label" variant='contained' size='small' startIcon={<UploadIcon />}>
                                {editing && link ? 'Replace with file' : 'Upload file'}
                              <Input ref={fileInputRef} id="contained-button-file" sx={{ display: 'none' }} type="file" onInput={handlePickFile} />
                            </Button>
                        </div>
                    </Stack>}
            <Collapse in={YouTube.isValidYouTubeLink(link) || YouTube.isValidSafeYTLink(link)} unmountOnExit>
                <div className='flex flex-col justify-around'>
                    <Button size={'small'} onClick={toggleEditVideo}>{ editingVideo ? 'Hide Editor' : 'Edit Video'}</Button>
                    <Controller
                        control={form.control}
                        name="link"
                        rules={{ required: true }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <FormControl error={!!error} variant="standard">
                                <InputLabel htmlFor="link" shrink>SafeYT Link</InputLabel>
                                <Input
                                    disabled={true}
                                    id="sytlink"
                                    value={value}
                                    onChange={onChange}
                                    aria-describedby="link"
                                />
                            </FormControl>
                        )}
                    />
                </div>
            </Collapse>
            <Collapse in={editingVideo}>
              <SafeYTVideoEditor
                isEditMode={true}
                link={link}
                onSafeYTLinkChange={handleSafeYTLinkChange}
                height={300}
                width={500}></SafeYTVideoEditor>
            </Collapse>
        </>
    )
}
