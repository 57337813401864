export default class GoogleClient {
  static AddedContentSharedDriveId: string = "0AChrdAv4MJNZUk9PVA";
  static ParentalContentSharedDriveId: string = "0AJlTgzz2jZ2gUk9PVA";
  static PBEEJApiServiceAccountEmail: string = "workspace-account@pbeej-343804.iam.gserviceaccount.com";

  static async addCollaborators(fileId: string, collaboratorsEmails: string[]): Promise<void> {
    console.log(`GoogleClient.addCollaborators (fileId = ${fileId}, adding ${collaboratorsEmails.length})`);
    await Promise.all(collaboratorsEmails.map((email) =>
      gapi.client.drive.permissions.create({
        fileId,
        emailMessage: "You've been added as a collaborator on a PBEEJ document!",
        sendNotificationEmail: true,
        supportsAllDrives: true,
      }, {
        emailAddress: email,
        role: 'writer',
        type: 'user'
      })
    ));
  };

  static async addServiceAccountAsCollaborator(fileId: string): Promise<void> {
    console.log(`GoogleClient.addServiceAccountAsCollaborator (fileId = ${fileId})`);
    await GoogleClient.addCollaborators(fileId, [this.PBEEJApiServiceAccountEmail]);
  }

  static async removeAllCollaborators(fileId: string): Promise<any> {
    const collaboratorPermissions = await gapi.client.drive.permissions.list({
      fileId,
      supportsAllDrives: true,
    }).then((response) => {
      const allPermissions = response.result.permissions ?? [];
      return allPermissions.filter((permission) =>
        permission.type === 'user'
        && permission.role !== 'owner'
        && permission.emailAddress !== this.PBEEJApiServiceAccountEmail
      );
    });
    console.log(`GoogleClient.removeAllCollaborators (fileId = ${fileId}, removing ${collaboratorPermissions.length})`);
    await Promise.all(collaboratorPermissions.map((permission) => gapi.client.drive.permissions.delete({
      fileId,
      permissionId: permission.id ?? "",
      supportsAllDrives: true,
    })));
  }

  static async addDomainReadPermission(fileId: string): Promise<any> {
    console.log(`GoogleClient.addDomainReadPermission (fileId = ${fileId})`);
    return gapi.client.drive.permissions.create({
      fileId,
      supportsAllDrives: true,
    }, {
      domain: 'pbeej.com',
      role: 'reader',
      type: 'domain',
    });
  }

  static async removeDomainReadPermission(fileId: string): Promise<any> {
    console.log(`GoogleClient.removeDomainReadPermission (fileId = ${fileId})`);
    return gapi.client.drive.permissions.list({
      fileId,
      supportsAllDrives: true,
    }).then((response) => {
      const domainPermission = response.result.permissions?.find(permission => permission.type === "domain");
      if (domainPermission) {
        return gapi.client.drive.permissions.delete({
          fileId,
          permissionId: domainPermission.id ?? "",
          supportsAllDrives: true,
        });
      }
      return Promise.resolve(null);
    });
  }

  static async isOnSharedDrive(fileId: string): Promise<boolean> {
    var fileResponse = await gapi.client.drive.files.get({
      fileId,
      supportsAllDrives: true,
    });
    const { driveId } = fileResponse.result;
    return driveId?.length > 0 ?? false;
  }

  static async moveToSharedDrive(fileId: string, isParentalContent = false): Promise<any> {
    console.log(`GoogleClient.moveToSharedDrive (fileId = ${fileId}, parental = ${isParentalContent})`);
    await gapi.client.drive.files.update({
      fileId,
      resource: {},
      supportsAllDrives: true,
      // Note: addParents refers to adding "parent" folder (in this case, a Shared Drive) to a Google Drive item, whereas isParentalContent is a PBEEJ concept :)
      addParents: isParentalContent
        ? this.ParentalContentSharedDriveId
        : this.AddedContentSharedDriveId,
      removeParents: isParentalContent
        ? this.AddedContentSharedDriveId
        : this.ParentalContentSharedDriveId,
    });
  }

  static async deleteMyDriveFile(fileId: string): Promise<any> {
    console.log(`GoogleClient.deleteMyDriveFile (fileId = ${fileId}`);
    return gapi.client.drive.files.delete({
      fileId,
    });
  }
};
