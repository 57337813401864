import { useState, useEffect } from 'react';
import ContentItem from '../../models/ContentItem';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  List,
} from '@mui/material';
import Item from '../content/Item';
import { useAppContext } from '../AppContext';

export default function Collection() {
  let { list: collection, setList: setCollection } = useAppContext()
  const [items, setItems] = useState<ContentItem[]>([]);

  useEffect(() => {
    setItems(collection);
  }, [collection])

  const removeItem = (idToDelete: number) => setCollection(collection.filter(item => item.id !== idToDelete));

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      backgroundColor: "#EFEFEF",
      borderRadius: '10px',
      padding: '15px 15px 10px 0px',
    }}>
      {items.length ? <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          height: '100%',
          paddingTop: 0,
          '& ul': { padding: '6px' },
        }}
      >
        {items.map((result) => {
          return (
            <div className="flex" key={result.id}>
              <IconButton
                onClick={() => removeItem(result.id)}
                size="small"
                edge="start"
                color="primary"
                sx={{ height: 'fit-content', m: 'auto 12px', padding: '4px' }}
              >
                <CloseIcon />
              </IconButton>
              <div style={{ flex: 1 }}>
                <Item
                  renderedInList={true}
                  key={result.id}
                  result={result}
                  previewPanelId={result.id}
                  onSelect={() => { }}
                  notifyIsVisible={() => { }}
                  editingList={false}
                />
              </div>
            </div>
          )
        })}
      </List> :
        <div className="flex items-center justify-center">
          <div className="text-center">
            <p className="text-gray-500">Add items by selecting them from the search results</p>
          </div>
        </div>
      }
    </div>
  )
}
