import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"

export const CloseConfirmation = ({ loseChanges, keepEditing }: any) => {
  return (
    <Dialog
      open={true}
      onClose={() => keepEditing()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to leave this page?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Any unsaved changes will be lost
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size={'small'} autoFocus onClick={() => keepEditing()} variant="outlined" color="primary">Cancel</Button>
        <Button size={'small'} onClick={() => loseChanges()} variant="contained" color="success">Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}
