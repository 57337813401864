import GoogleClientBase from "./GoogleClientBase";

class Spreadsheet extends GoogleClientBase {
  getLink(fileId: string): string {
    return `https://docs.google.com/spreadsheets/d/${fileId}/edit#gid=0`;
  }

  async create(title: string): Promise<string> {
    const response = await gapi.client.sheets.spreadsheets.create({
      resource: {
        properties: {
          title
        }
      }
    });

    const { spreadsheetId } = response.result;

    if (!spreadsheetId) {
      throw new Error("Google Sheet created with empty ID.");
    }

    return spreadsheetId;
  }
};

export default new Spreadsheet();
