import {
  Chip
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TagModel from '../../models/Tag';

interface TagInterface {
  editable: boolean,
  tag?: TagModel,
  deleteTag: Function,
  clickHandler?: Function
}

export default function Tag({ editable, tag, deleteTag, clickHandler }: TagInterface) {
  const tagBgColor = (tagType: string | undefined) => {
    if (tagType === 'AgeRange') {
      return 'secondary.light';
    } else if (tagType === 'FreeFlag' || tagType === 'PbeejOnlyFlag' || tagType === 'ParentResourceFlag') {
      return 'success.light';
    } else if (tagType === 'Collaborator' || tagType === 'ContentType') {
      return 'info.light';
    } else {
      return 'primary.light';
    }
  }

  const tagTextColor = (tagType: string | undefined) => {
    if (tagType === 'AgeRange') {
      return 'secondary.main';
    } else if (tagType === 'FreeFlag' || tagType === 'PbeejOnlyFlag' || tagType === 'ParentResourceFlag') {
      return 'success.main';
    } else if (tagType === 'Collaborator' || tagType === 'ContentType') {
      return 'info.main';
    } else {
      return 'primary.main';
    }
  }

  return (
    <Chip
      key={tag ? tag.Id : ''} 
      size={'small'}
      label={tag ? tag.Text : ''} 
      onClick={() => clickHandler ? clickHandler() : null}
      style={{cursor: clickHandler ? 'pointer' : 'default'}}
      sx={{ 
        margin: '1px', 
        marginLeft: '2px', 
        color: tagTextColor(tag?.TagType), 
        backgroundColor: tagBgColor(tag?.TagType),
        "&:hover": {
          color: tagTextColor(tag?.TagType), 
          backgroundColor: tagBgColor(tag?.TagType),
        }
      }}
      onDelete={editable ? () => deleteTag(tag) : null}
      deleteIcon={<ClearIcon sx={{color: 'primary'}} />}
    />
  )
}
