import { Button } from "@mui/material";
import getContentTypeIcon, { getContentActionText } from "../content/ContentIcon";
import openLinkInNewTab from "../../helpers/openLinkInNewTab";

interface LinkProps {
    link: string,
    typeText: string
}

export default function Link({ link, typeText }: LinkProps) {

    return (
        <Button
            variant="contained"
            endIcon={getContentTypeIcon(typeText)}
            onClick={() => {
                openLinkInNewTab(link);
            }}
            sx={{
                borderRadius: "50px",
                height: "2rem",
                margin: "2rem auto",
            }}
        >
            {getContentActionText(typeText)}
        </Button>
    );
}