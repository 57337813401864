import { createTheme } from "@mui/material";

// TODO: revamp theme
export const theme = createTheme({
    palette: {
        // mode: 'dark',
        primary: {
            main: '#d60d51',
            light: '#FAD9E4'
        },
        secondary: {
            main: '#fcad0d',
            light: '#fff5d4',
        },
        info: {
            main: '#038DAD',
            light: '#D2F2FA'
        },
        success: {
            main: '#019C11',
            light: '#C0EECA'
        },
    },
    typography: {
        caption: {
            fontSize: 12,
        },
    }
});