import Tag from './Tag';
import TagModel from '../../models/Tag';
import { useCallback } from 'react';
import { SelectedTags } from '../../helpers/discover';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';

export default function FilterBar({
  keywords,
  contentTypes,
  ageRanges,
  selectedTags,
  setMultiSelectTags,
  clearMultiSelectTags,
  setBooleanFlags
}: {
  keywords: TagModel[],
  contentTypes: TagModel[],
  ageRanges: TagModel[],
  selectedTags: SelectedTags,
  setMultiSelectTags: Function,
  clearMultiSelectTags: Function,
  setBooleanFlags: Function
}) {

  const deleteTag = useCallback((tag): void => {
    if (tag.TagType === 'Keyword') {
      let tempKeywordSet = new Set(selectedTags.keywords);
      tempKeywordSet.delete(tag.Id);
      setMultiSelectTags('keywords', tempKeywordSet);
    }
    if (tag.TagType === 'ContentType') {
      let tempTypeSet = new Set(selectedTags.type);
      tempTypeSet.delete(tag.Id);
      setMultiSelectTags('type', tempTypeSet);
    }
    if (tag.TagType === 'AgeRange') {
      let tempAgeRange = new Set(selectedTags.ageRange);
      tempAgeRange.delete(tag.Id);
      setMultiSelectTags('ageRange', tempAgeRange);
    }
    if (tag.TagType === 'FreeFlag') {
      setBooleanFlags([{ flagName: 'freeFlag', flagOn: false}]);
    }
    if (tag.TagType === 'PbeejOnlyFlag') {
      setBooleanFlags([{ flagName: 'pbeejOnlyFlag', flagOn: false }]);
    }
    if (tag.TagType === 'ParentResourceFlag') {
      setBooleanFlags([{ flagName: 'parentResourceFlag', flagOn: false }]);
    }
  }, [selectedTags, setMultiSelectTags, setBooleanFlags]);


  const tagsPresent = (
    selectedTags.keywords.size === 0 &&
    selectedTags.ageRange.size === 0 &&
    selectedTags.type.size === 0 &&
    selectedTags.parentResourceFlag.valueOf() === false &&
    selectedTags.pbeejOnlyFlag.valueOf() === false &&
    selectedTags.freeFlag.valueOf()=== false
    );

  return (
    <div className={`tags w-full flex flex-wrap place-content-start place-items-center`} style={{ padding: 4 }}>
      {!(tagsPresent) &&
      <IconButton className={'!text-secondary !font-bold'} size={'medium'} onClick={() => clearMultiSelectTags()}>
        <ClearIcon className={'text-secondary'} fontSize={'inherit'} />
      </IconButton>
      }
      {Array.from(selectedTags.keywords).map((keyword) => {
        return (
          <Tag
            editable={true}
            key={keyword}
            tag={keywords.find(element => element.Id === keyword)}
            deleteTag={deleteTag}
          />
        )
      })}
      {Array.from(selectedTags.type).map((contentType) => {
        return (
          <Tag
            editable={true}
            key={contentType}
            tag={contentTypes.find(element => element.Id === contentType)}
            deleteTag={deleteTag}
          />
        )
      })}
      {Array.from(selectedTags.ageRange).map((ageRange) => {
        return (
          <Tag
            editable={true}
            key={ageRange}
            tag={ageRanges.find(element => element.Id === ageRange)}
            deleteTag={deleteTag}
          />
        )
      })}
      {selectedTags.freeFlag &&
        <Tag
          editable={true}
          key={'FreeFlag'}
          tag={{ TagType: 'FreeFlag', Id: 1, Text: 'Free' }}
          deleteTag={deleteTag}
        />
      }
      {selectedTags.pbeejOnlyFlag &&
        <Tag
          editable={true}
          key={'PbeejOnlyFlag'}
          tag={{ TagType: 'PbeejOnlyFlag', Id: 1, Text: 'PBEEJ Only' }}
          deleteTag={deleteTag}
        />
      }
      {selectedTags.parentResourceFlag &&
        <Tag
          editable={true}
          key={'ParentResourceFlag'}
          tag={{ TagType: 'ParentResourceFlag', Id: 1, Text: 'PG' }}
          deleteTag={deleteTag}
        />
      }
    </div>
  );
}
