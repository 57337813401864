import { useCallback, useEffect, useState } from 'react';
import {
  List,
  ListSubheader,
  Fab,
} from '@mui/material';
import { SelectedTags } from '../../helpers/discover';
import Tag from '../../models/Tag';
import { searchContent, groupBy, getContentAll, markMessageAcknowledged } from '../../helpers/helpers';
import ContentItem from '../../models/ContentItem';
import Item from './Item';
import { useLocation } from 'react-router';
import getContentTypeIcon from './ContentIcon';
import { MessageAcknowledgement } from '../util/MessageAcknowledgement';
import { useAppContext } from '../AppContext';

interface ContentListInterface {
  contentTypes: Tag[],
  keywords: Tag[],
  ageRanges: Tag[],
  selectedTags: SelectedTags,
  detailsId: number | undefined,
  onResourceSelected: (item: ContentItem) => void
}

interface paramsModel {
  keywordIds: number[],
  ageIds: number[],
  contentTypeIds: number[],
  title: string,
  free: boolean,
  pbeejOnly: boolean,
  parentContent: boolean,
  myActive: boolean,
  myWorkInProgress: boolean,
  myPrivate: boolean
}

const defaultNumberOfCardsToShow = 20;

export default function ContentList({ contentTypes, keywords, ageRanges, selectedTags, detailsId, onResourceSelected, ...props }: ContentListInterface) {
  const [results, setResults] = useState<ContentItem[]>([]);
  const [editingCollection, setEditingCollection] = useState<boolean>(false);
  const [numberOfCardsToShow, setNumberOfCardsToShow] = useState<number>(defaultNumberOfCardsToShow);
  const [messageToAcknowledge, setMessageToAcknowledge] = useState<string>("");
  const [queuedResults, setQueuedResults] = useState<ContentItem[]>([]);
  let { creatingList } = useAppContext();

  const location = useLocation();

  const editing = location.pathname.includes("/edit");
  const cloning = location.pathname.includes("/clone");

  const acknowledgeMessage = useCallback(() => {
    setMessageToAcknowledge("");
    setResults(queuedResults);
    setQueuedResults([]);
    markMessageAcknowledged();
  }, [queuedResults]);

  useEffect(() => {
    const params: paramsModel = {
      keywordIds: Array.from(selectedTags.keywords),
      ageIds: Array.from(selectedTags.ageRange),
      contentTypeIds: Array.from(selectedTags.type),
      title: selectedTags.titleSearch,
      free: selectedTags.freeFlag,
      pbeejOnly: selectedTags.pbeejOnlyFlag,
      parentContent: selectedTags.parentResourceFlag,
      myActive: selectedTags.myActive,
      myWorkInProgress: selectedTags.myWorkInProgress,
      myPrivate: selectedTags.myPrivate
    }

    searchContent(params).then((data) => {
      if (data.alertMessage.length > 0) {
        setMessageToAcknowledge(data.alertMessage);
        setQueuedResults(data.searchResults);
      } else {
        setResults(data.searchResults);
      }
    })
  }, [selectedTags, location]);

  useEffect(() => {
    if (results.length < numberOfCardsToShow) {
      setNumberOfCardsToShow(results.length > defaultNumberOfCardsToShow ? results.length : defaultNumberOfCardsToShow);
    }
  }, [results, numberOfCardsToShow]);

  useEffect(() => {
    (async() => {
      if (detailsId) {
        let typeId;
        await getContentAll(detailsId).then((data) => {
          typeId = data.contentType.id;
        })

        setEditingCollection((editing || cloning) && typeId === 34); // 34 == Collection Content Type
      } else {
        setEditingCollection(creatingList);
      }
    })()
  }, [detailsId, creatingList, editing, cloning])

  const handleIsVisible = useCallback((result: ContentItem) => {
    if (results.length > numberOfCardsToShow && result.id === results[numberOfCardsToShow - 5].id) {
      setNumberOfCardsToShow(numberOfCardsToShow + 20);
    }
  }, [numberOfCardsToShow, results]);

  return (
    <>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          height: '100%',
          paddingTop: 0,
          '& ul': { padding: '6px' },
        }}
      >
        <p id="back-to-top-anchor"></p>
        {
          Object.entries(groupBy(results.slice(0, numberOfCardsToShow), i => i.type_id)).map((result, sectionId) => {
            const type = contentTypes.filter(type => type.Id === parseInt(result[0]))[0];
            if (type == null) {
              return (<div key={`section-${sectionId}`} />)
            }
            else
              return (
                <li key={`section-${sectionId}`}>
                  <ul>
                    <span>
                    <ListSubheader sx={{ backgroundColor: '#FAFAFA', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '1rem'}}>
                      <span className="flex mr-1">{getContentTypeIcon(type.Text)}</span>{type.Text}
                    </ListSubheader>
                    </span>
                    {result[1].map((result) => {
                      return (
                        <Item
                          renderedInList={false}
                          key={result.id}
                          result={result}
                          previewPanelId={result.id}
                          onSelect={onResourceSelected}
                          notifyIsVisible={() => { handleIsVisible(result) }}
                          editingList={editingCollection}
                        />
                      )
                    }
                    )}
                  </ul>
                  <ul style={{ height: "0.3vh" }}></ul>
                </li>
              )
          })
        }
        {numberOfCardsToShow === results.length && <ListSubheader sx={{ backgroundColor: '#FAFAFA', marginTop: 0, textAlign: 'left' }}></ListSubheader>}
      </List>
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
        <Fab
          variant="extended"
          sx={{
            opacity: 0.95,
            position: 'absolute',
            height: 35,
            marginRight: 4,
            bottom: 35,
            right: 45,
            zIndex: 1000
          }}>
          <a href="#back-to-top-anchor" ><b>{results.length}</b>&nbsp;item{results.length === 0 || results.length > 1 ? 's' : ''}</a>
        </Fab>
      </div>
      {messageToAcknowledge.length > 0 &&
        <MessageAcknowledgement
          messageText={messageToAcknowledge}
          acknowledge={acknowledgeMessage}
        />
      }
    </>
  );
}
