import { useContext, useCallback } from 'react';
import { AuthContext } from '../AuthContext';
import AuthContextValue from '../models/AuthContextValue';
import { Navigate, useLocation } from "react-router-dom";
import logo from '../assets/pbeej-logo.svg';
import { Button } from '@mui/material';

export default function Login() {
  const location = useLocation();
  const { googleAuth, isAuthorized } = useContext<AuthContextValue>(AuthContext);

  const handleAuthClick = useCallback(async () => {
    // User is not signed in. Start Google auth flow.
    await googleAuth?.signIn();
  }, [googleAuth]);


  if (isAuthorized) {
    if (location?.state) return <Navigate to={location?.state?.prevUrl} replace={true}/>

    return <Navigate to={'/'} replace={true}/>
  }

  return (
    <div className={'w-full h-full flex flex-col justify-center'}>
      <div className='flex items-center justify-center m-5'>
        <img src={logo} alt={'pbeej logo svg'} className='w-[400px] lg:w-[500px]' />
      </div>
      <div className='flex items-center justify-center mt-10'>
        <Button onClick={handleAuthClick} variant="contained" size="large">
          Sign In
        </Button>
      </div>
    </div>
  )
}
