import axios from 'axios';
import GoogleClientBase from "./GoogleClientBase";

const axiosInstance = axios.create({
  baseURL: 'https://sites.google.com',
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.post['GData-Version'] = 1.4;
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] = '*';

class Website extends GoogleClientBase {

  getLink(fileId: string): string {
    return `https://sites.google.com/a/pbeej.com`;
  }

  async create(title: string): Promise<string> {
    //TODO implement create when google sites API is finished
    return "";
  }
};

export default new Website();
