import { getJWTDecoded } from "../../helpers/helpers";
import { FileRequest } from "./Drive";

export interface FileUploadError {
  status: string;
  fileName: string;
  error: gapi.client.Response<any>;
  fileMetadata: {
    type: string;
    size: number;
  };
  uploadMetadata?: {
    fileId?: string;
    requestCount: number;
    completedRequestCount: number;
  };
  failingRequest?: FileRequest;
}

export async function logFileUploadError(metadata: object) {
  // local debugging
  console.error(metadata);

  const userInfo = getJWTDecoded();
  const userName = `${userInfo.firstName} ${userInfo.lastName}`;

  // production debugging via telemetry
  await gapi.client.request({
    method: 'POST',
    path: 'https://logging.googleapis.com/v2/entries:write',
    body: {
      entries: [
        {
          logName: 'projects/pbeej-343804/logs/file-upload',
          resource: {
            type: 'logging_bucket',
            labels: {},
          },
          jsonPayload: {
            metadata,
            userName,
          },
        },
      ],
    },
  });
}