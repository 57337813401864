import EngineeringIcon from '@mui/icons-material/Engineering';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';

export function getIcon(status: string) {
    switch (status) {
        case "WIP":
            return (
                <EngineeringIcon color={'secondary'} sx={{ fontSize: '18px', marginRight: '2px', marginTop: '2px' }} />
            )
        case "COM":
            return (
                <LockIcon color={'primary'} sx={{ fontSize: '18px', margin: '0', marginTop: '2px' }} />
            )
        case "SHR":
            return (
                <GroupsIcon color={'info'} sx={{ fontSize: '20px', margin: '2px' }} />
            )
        default:
            return null;
    }
}