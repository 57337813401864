import { Grid, Card, Typography, CardContent, CardActionArea, Box, Button, } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ProjectStatusChip from './ProjectStatusChip';
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { addProjectContent } from "../../helpers/projects";
import AlertInfo from "../../models/AlertInfo";
import { AlertType } from "../../models/enums/AlertType";
import { AddToProjectResourceType } from "./ProjectsList";
import { getParams } from '../../helpers/discover';
import { useContext, useEffect, useMemo } from "react";
import AuthContextValue from '../../models/AuthContextValue';
import { AuthContext } from '../../AuthContext';
import { useAppContext } from "../AppContext";

interface ProjectPreviewCardInterface {
    project: any,
    addToProjectSelection: AddToProjectResourceType | undefined,
    setAddToProjectSelection: any
}

function useQuery() {
  const location = useLocation();

  let search = location.search;

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ProjectPreviewCard({ project, addToProjectSelection, setAddToProjectSelection }: ProjectPreviewCardInterface) {
    const { googleAccessToken } = useContext<AuthContextValue>(AuthContext);
    const query = useQuery();

    let { addingToProject, activeResource, setAddingToProject } = useAppContext();

    const navigate = useNavigate();

    const handleCardClick = (e: React.MouseEvent<HTMLButtonElement>, projectId: number) => {
        e.stopPropagation();
        if(addingToProject) {
            if(addToProjectSelection?.projectId === project.id) {
                setAddToProjectSelection(undefined);
            } else {
                setAddToProjectSelection({ projectId: project.id, project: project.title });
            }
        } else {
            navigate({
                pathname: `/project/${projectId}`,
                search: `?${createSearchParams({
                  ...getParams(query)
                })}`
            })
        }
    }

    const navigateToDiscover = (alertInfo: AlertInfo | undefined = undefined) => {
        navigate({
            pathname: '/',
            search: `?${createSearchParams({
              ...getParams(query)
            })}`
        },
            {
                state: {
                    alert: alertInfo
                }
            })
    }

    const handleAddToProject = (projectId: number, resourceId: number) => {
        addProjectContent(projectId, resourceId, "RES", googleAccessToken).then(() => {
            setAddingToProject(false)
            let alert: AlertInfo = {
                type: AlertType.Success,
                message: `Successfully added to ${addToProjectSelection.project}!`,
                dismissable: true
            };
            navigateToDiscover(alert);
        }).catch((err) => {
            let alert: AlertInfo = {
                type: AlertType.Error,
                message: "Sorry. Something went wrong. Please try that again.",
                dismissable: true
            };
            navigateToDiscover(alert);
        })
        setAddToProjectSelection(undefined);
    }

    useEffect(() => {
        if(!addingToProject) {
            setAddToProjectSelection(undefined);
        }
    }, [addingToProject, setAddToProjectSelection])

    return (
        <Grid item xs={6} sm={4} md={3} key={project.id}>
            <Card sx={[
                addingToProject ? {

                }: {},
                {
                    maxWidth: 400
                }
            ]}>
                <CardActionArea onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCardClick(e, project.id)}>
                    <CardContent>
                        <Typography variant="h6" component="div" align="left" noWrap>
                            {project.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" noWrap>
                            {project.description}
                        </Typography>
                        <Box sx={{ display: "flex", height: "35px", alignItems: "end", justifyContent: "start" }}>
                            <ProjectStatusChip chevron={false} status={project.status} />
                        </Box>
                    </CardContent>
                    <CardContent sx={{ justifyContent: "space-around" }}>
                        {addingToProject && addToProjectSelection && addToProjectSelection.projectId === project.id &&
                            <Button size="small" variant={'outlined'} color="success" onClick={() => handleAddToProject(project.id, activeResource)}>
                                <CheckIcon />
                            </Button>
                        }
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}
