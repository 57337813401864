import "./Reminders.css";
import astronaut from "../../assets/astronaut.gif";
import germ from "../../assets/germ.gif";
import guitar from "../../assets/guitar.gif";
import paint from "../../assets/paint.gif";
import pipes from "../../assets/pipeworks.gif";
import plant from "../../assets/plant.gif";
import vehicle from "../../assets/vehicle.gif";
import Sparkle from 'react-sparkle';

export const Guitar = (
  <span>
    <img className="w-4/5 m-auto mb-5 reminder" src={guitar} alt="guitar"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go write the symphony, pick up the guitar, make a new chord progression, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Germ = (
  <span>
    <img className="w-4/5 m-auto mb-5 reminder" src={germ} alt="germ"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go sample some pondwater, look through the microscope, discover a new species, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Astronaut = (
  <span>
    <img className="w-4/5 m-auto mb-5 reminder" src={astronaut} alt="astronaut"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go look through the telescope, experiment with gravity, build the rocket, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Paint = (
  <span>
    <img className="w-3/5 m-auto mb-5 reminder" src={paint} alt="paint"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go mix some colors, try out a new brush technique, paint the masterpiece, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Pipes = (
  <span>
    <img className="w-3/5 m-auto mb-5 reminder" src={pipes} alt="pipes"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go build the oil well, explore the ductwork in the house, follow the water down the drain, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Plant = (
  <span>
    <img className="w-4/5 m-auto mb-5 reminder" src={plant} alt="plant"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go look for seeds, build a composter, plant the garden, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={50} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);

export const Vehicle = (
  <span>
    <img className="w-4/5 m-auto mb-5 reminder" src={vehicle} alt="vehicle"/>
    <p className="w-4/5 m-auto mb-5 first">Remember, writing <span className="about">ABOUT</span> what you've learned is only useful if you first <span className="do">DO</span> the work.</p>
    <p className="w-4/5 m-auto mb-5 second">Go find out how electricity works, take apart the car engine, design a new type of fuel, then come here and write about it!</p>
    <span className="w-4/5 m-auto mb-5 block relative third">
      <Sparkle color={'#D4AF37'} fadeOutSpeed={20} maxSize={20} minSize={10} count={5}/>
      <p>PROJECT based learning!</p>
    </span>
  </span>
);
