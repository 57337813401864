import { useState, MouseEvent, Fragment } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PlusIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { menuOptions } from '../../models/enums/NewOptions';
import { useMediaQuery } from 'react-responsive';

export default function AddContentDropdown({ onModeSelected }: { onModeSelected: (mode: menuOptions) => void }) {
  const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
  const addOpen = Boolean(addAnchorEl);
  const isMobile = useMediaQuery({ query: '(max-width: 985px)' });
  const handleAddClick = (event: MouseEvent<HTMLElement>) => {
    setAddAnchorEl(event.currentTarget);
  };
  const handleAddClose = () => {
    setAddAnchorEl(null);
  };

  const handleAddContentClick = () => {
    onModeSelected(menuOptions.addContent);
  }

  const handleCreateContentClick = () => {
    onModeSelected(menuOptions.addNew);
  }

  const handleCreateCollectionClick = () => {
    onModeSelected(menuOptions.addCollection);
  }

  const handleNewProjectClick = () => {
    onModeSelected(menuOptions.addProject);
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Add Content">
          {isMobile ?
            <IconButton
              onClick={handleAddClick}
              aria-controls={addOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={addOpen ? 'true' : undefined}
              sx={{ color: "white", padding: '0 0 0 8px' }}
            >
              <AddIcon fontSize="large"/>
            </IconButton>
            :
            <Button
              startIcon={<PlusIcon />}
              onClick={handleAddClick}
              size={'small'}
              sx={{ color: 'white', borderColor: 'white', ml: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.25)" } }}
              aria-controls={addOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={addOpen ? 'true' : undefined}
              variant="outlined"
            >
              Add
            </Button>
          }
        </Tooltip>
      </Box>
      <Menu
        anchorEl={addAnchorEl}
        id="account-menu"
        open={addOpen}
        onClose={handleAddClose}
        onClick={handleAddClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleNewProjectClick}>
          Create Project
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAddContentClick}>
          Add Existing Content
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCreateContentClick}>
          Create Something New
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCreateCollectionClick}>
          Create a Collection
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
