import './App.css';
import AppRouter from './AppRouter';
import AuthContextProvider from './AuthContext';
import SplashScreen from './components/SplashScreen';
import { ThemeProvider } from '@mui/material';
import { theme } from './muiTheme';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <SplashScreen>
          <div className={`text-center w-full h-full`}>
            <AppRouter />
          </div>
        </SplashScreen>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
