import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { StyledInputBase } from "./CustomStyledComponents";
import {
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { createSearchParams, useNavigate } from "react-router-dom";
import { getParams } from "../../helpers/discover";
import { useQuery } from "../../helpers/helpers";
import { useDebouncedCallback } from "use-debounce";

interface TitleSearchProps {
  titleSearchValue: string
}

export default function TitleSearch({ titleSearchValue }: TitleSearchProps) {
  const [value, setValue] = useState<string>(titleSearchValue);
  const query = useQuery();
  const navigate = useNavigate();

  const titleSearchCallback = useCallback((newTitleSearch: string): void => {
    navigate({
      search: `?${createSearchParams({
        ...getParams(query),
        titleSearch: newTitleSearch
      })}`
    })
  }, [navigate, query]);

  const debouncedTitleSearch = useDebouncedCallback((titleSearchChange: string) => {
    titleSearchCallback(titleSearchChange);
  }, 500);
  
  const onValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debouncedTitleSearch(event.target.value);
  }, [debouncedTitleSearch]);

  useEffect(() => {
    setValue(titleSearchValue);
  }, [titleSearchValue]);

  const handleClearClick = () => {
    setValue("");
    debouncedTitleSearch("");
  }

  return <StyledInputBase
    placeholder="Search PBEEJ"
    inputProps={{ 'aria-label': 'search' }}
    value={value}
    onChange={onValueChange}
    endAdornment={<IconButton onClick={handleClearClick} sx={{ color: "#fff", visibility: value ? "visible": "hidden" }}><ClearIcon /></IconButton>}
    fullWidth
  />;
}
