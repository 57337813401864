import { Container, AppBar, Box, Toolbar, Typography, IconButton, Button } from "@mui/material"
import { Astronaut, Germ, Guitar, Paint, Pipes, Plant, Vehicle } from "../reminders/Reminders";
import CloseIcon from '@mui/icons-material/Close';
import { getParams } from '../../helpers/discover';
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { useMemo } from "react";

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
}

const useQuery = () => {
  const location = useLocation();
  let search = location.search;

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Reminder() {
  const navigate = useNavigate();
  const query = useQuery();

  const navigateToDiscover = () => {
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        ...getParams(query)
      }
      )}`
    })
  }

  const navigateToAddNew = () => {
    navigate({
      pathname: "/add/new",
      search: `?${createSearchParams({
        ...getParams(query)
      }
      )}`
    })
  }

  let currentReminder = getRandomInt(7);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: 'white', color: 'black', marginBottom: '20px' }} position="static">
        <Toolbar>
          <IconButton
            onClick={() => navigateToDiscover()}
            size="small"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Create Content
          </Typography>
        </Toolbar>
      </AppBar>

      <Container
        sx={{
          height: '100%'
        }}
      >
        {currentReminder === 0 && Astronaut}
        {currentReminder === 1 && Germ}
        {currentReminder === 2 && Guitar}
        {currentReminder === 3 && Paint}
        {currentReminder === 4 && Pipes}
        {currentReminder === 5 && Plant}
        {currentReminder === 6 && Vehicle}
        <Button
          onClick={() => navigateToAddNew()}
          color={'primary'}
          variant={'contained'}
          size={'small'}
          className={'lets-go'}
        >
          Let's Go!
        </Button>
      </Container>
    </Box>
  )
}
