import { useState, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Drawer, CssBaseline, Toolbar, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation, createSearchParams, useSearchParams, useParams, Outlet } from "react-router-dom";
import FilterDrawer from './FilterDrawer';
import AddContentDropdown from './AddContentDropdown';
import ProfileDropdown from './ProfileDropdown';
import FilterBar from '../tags/FilterBar';
import ContentList from '../content/ContentList';
import ProjectsList from '../projects/ProjectsList';
import { getParams, convertToSelectedTags, BooleanFilter, ReminderAlert } from '../../helpers/discover';
import Tag from '../../models/Tag';
import { getTagList, getJWTDecoded, useQuery } from '../../helpers/helpers';
import { AppBar, Search, Main, DrawerHeader } from './CustomStyledComponents';
import ContentItem from '../../models/ContentItem';
import AlertInfo from '../../models/AlertInfo';
import { AlertSnack } from '../util/Alerts';
import TitleSearch from './TitleSearch';
import AdultOnly from '../shared/AdultOnly';
import { useAppContext } from '../AppContext';
import { menuOptions } from '../../models/enums/NewOptions';

export const detailsDrawerWidth = 600;

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function Discover() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const selectedTags = useMemo(() => convertToSelectedTags(query), [query]);
  const queryParams = useParams();
  const [filterPanelOpen, setFilterPanelOpen] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<Tag[]>([]);
  const [contentType, setContentType] = useState<Tag[]>([]);
  const [ageRange, setAgeRange] = useState<Tag[]>([]);
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);
  const resourceId: number | undefined = queryParams.id && !location.pathname.includes("project") ? parseInt(queryParams.id) : undefined;
  const isMobile = useMediaQuery({
    query: '(max-width: 985px)'
  });

  let { addingToProject } = useAppContext();

  const navigateTo = (pathname: string) => {
    navigate({
        pathname: pathname,
        search: `?${createSearchParams({
            ...getParams(query)
        }
        )}`
    })
}

const navigateModeSelected = (newMode: menuOptions) => {
    switch (newMode) {
        case menuOptions.addContent:
            navigateTo("/add/content");
            break;
        case menuOptions.addCollection:
            navigateTo("/add/collection");
            break;
        case menuOptions.addNew:
            navigateTo("/add/reminder");
            break;
        case menuOptions.addProject:
            navigateTo("/project/new");
            break;
    }
}

  const openRightPanel = useMemo(() => {
    if (isMobile && addingToProject) {
      return false;
    }
    return !!resourceId || location.pathname.split('/')[1].length > 0;
  }, [isMobile, addingToProject, location.pathname, resourceId])

  const userInfo = getJWTDecoded();

  const handleDrawerOpen = useCallback(() => {
    setFilterPanelOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setFilterPanelOpen(false);
  }, []);

  function handleResourceSelected(newResource: ContentItem) {
    navigateTo(`/details/${newResource.id}`);
  };

  const clearMultiSelectTags = useCallback(() => {
    searchParams.delete('keywords');
    searchParams.delete('type');
    searchParams.delete('ageRange');
    searchParams.delete('freeFlag')
    searchParams.delete('pbeejOnlyFlag')
    searchParams.delete('parentResourceFlag')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams]);

  const setMultiSelectTags = useCallback((tagName, tags: Set<number>): void => {
    navigate({
      search: `?${createSearchParams({
        ...getParams(query),
        [tagName]: Array.from(tags).toString()
      })}`,
    });
  }, [navigate, query]);

  const setBooleanFlags = useCallback((filters: BooleanFilter[]): void => {
    let booleanParams: any = {};
    filters.forEach(filter => {
      booleanParams[filter.flagName] = filter.flagOn.toString();
    });

    navigate({
      search: `?${createSearchParams({
        ...getParams(query),
        ...booleanParams
      })}`
    })
  }, [navigate, query]);

  useEffect(() => {
    getTagList().then((data) => {
      setKeywords(data.Keyword);
      setContentType(data.ContentType);
      setAgeRange(data.AgeRange);
    })
  }, []);

  useEffect(() => {
    const alert = location.state?.alert;
    if (alert) {
      setAlert(alert);
      navigate({ search: `?${createSearchParams({ ...getParams(query) })}` }, { state: null });
    }
  }, [location.state?.alert, navigate, query])

  const hasFilterPanelFiltersApplied = selectedTags.keywords.size > 0
    || selectedTags.type.size > 0
    || selectedTags.ageRange.size > 0
    || selectedTags.freeFlag
    || selectedTags.pbeejOnlyFlag
    || selectedTags.parentResourceFlag
    || selectedTags.myActive
    || selectedTags.myWorkInProgress
    || selectedTags.myPrivate;

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" filterPanelOpen={filterPanelOpen} detailsOpen={openRightPanel} isMobile={isMobile} sx={{ backgroundColor: '#ba0a45' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(filterPanelOpen ? { display: 'none' } : {}) }}
          >
            <FilterListIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, flexShrink: 1, alignItems: 'left', textAlign: 'left' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <TitleSearch
                titleSearchValue={selectedTags.titleSearch}
              />
            </Search>
          </Box>
          <AdultOnly>
            <AddContentDropdown
              onModeSelected={navigateModeSelected}
            />
          </AdultOnly>
          <ProfileDropdown />
        </Toolbar>
      </AppBar>

      <FilterDrawer
        onClose={handleDrawerClose}
        open={filterPanelOpen}
        keywordsReceived={keywords}
        contentTypesReceived={contentType}
        ageRangesReceived={ageRange}
        selectedTags={selectedTags}
        setMultiSelectTags={setMultiSelectTags}
        setBooleanFlags={setBooleanFlags}
      />
      <Main filterPanelOpen={filterPanelOpen} detailsOpen={openRightPanel} isMobile={isMobile}>
        <DrawerHeader />  {/* spacer to keep content from being hidden by the AppBar */}
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <>
            <FilterBar
              keywords={keywords}
              contentTypes={contentType}
              ageRanges={ageRange}
              selectedTags={selectedTags}
              setMultiSelectTags={setMultiSelectTags}
              clearMultiSelectTags={clearMultiSelectTags}
              setBooleanFlags={setBooleanFlags}
            />
            <ProjectsList
              hasFilterPanelFiltersApplied={hasFilterPanelFiltersApplied}
              searchText={selectedTags.titleSearch}
            />
            <ContentList
              contentTypes={contentType}
              keywords={keywords}
              ageRanges={ageRange}
              selectedTags={selectedTags}
              detailsId={resourceId}
              onResourceSelected={handleResourceSelected}
            />
          </>
        </Box>
      </Main>
      <Drawer
        sx={{
          width: openRightPanel ? (isMobile ? "100%" : detailsDrawerWidth) : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: openRightPanel ? (isMobile ? "100%" : detailsDrawerWidth) : 0,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={openRightPanel}
      >
        <Outlet />
      </Drawer>
      {alert && <AlertSnack alertInfo={alert} setAlert={setAlert} />}
      {(userInfo.daysSinceLastNewContent > 5 && userInfo.loginsSinceLastNewContent > 5) && <AlertSnack alertInfo={ReminderAlert} setAlert={setAlert} />}
    </Box>
  );
}
