import Chip from '@mui/material/Chip';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';

export function StatusTag({ text }: any) {
  switch (text) {
    case 'WIP':
    case 'Work in Progress':
      return (
        <Chip icon={<EngineeringIcon color='secondary'/>} label='In Progress' size='small' sx={{ margin: 'auto 0px', backgroundColor: 'secondary.light', color: 'secondary' }}/>
      );
    case 'SHR':
    case 'Share with Some':
      return (
        <Chip icon={<GroupsIcon color='info'/>} label='Shared' size='small' sx={{ margin: 'auto 0px', backgroundColor: 'info.light', color: 'info' }}/>
      );
    case 'COM':
    case 'Private':
      return (
        <Chip icon={<LockIcon color='primary'/>} label='Private' size='small' sx={{ margin: 'auto 0px', backgroundColor: 'primary.light', color: 'primary' }}/>
      );
    default:
      return null;
  }
}

export function MiniStatusTag({ text }: any) {
  switch (text) {
    case 'WIP':
    case 'Work in Progress':
      return (
        <Chip icon={<EngineeringIcon sx={{ marginRight: '-13px !important' }} color='secondary'/>} size='small' sx={{ margin: 'auto 0px', backgroundColor: 'secondary.light', color: 'secondary' }}/>
      );
    case 'SHR':
    case 'Share with Some':
      return (
        <Chip icon={<GroupsIcon sx={{ marginRight: '-13px !important' }} color='info'/>} size='small' sx={{ margin: 'auto 0px', backgroundColor: 'info.light', color: 'info' }}/>
      );
    case 'COM':
    case 'Private':
      return (
        <Chip icon={<LockIcon sx={{ marginRight: '-13px !important' }} color='primary'/>} size='small' sx={{ margin: 'auto 0px', backgroundColor: 'primary.light', color: 'primary' }}/>
      );
    default:
      return null;
  }
}
