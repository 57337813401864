import {
  List,
} from '@mui/material';
import Item from '../content/Item';
import ChildContentItems from '../../models/ChildContentItems';
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from 'react';
import { createSearchParams } from 'react-router-dom';
import { getParams } from '../../helpers/discover';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ItemsListView({ items }: {items?: ChildContentItems[]}) {
  const navigate = useNavigate();
  const query = useQuery();

  return (
    <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          backgroundColor: "#EFEFEF",
          borderRadius: '10px',
          padding: '15px 15px 10px 15px',
      }}>
      {items?.length ? <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          height: '100%',
          paddingTop: 0,
          '& ul': { padding: '6px' },
        }}
      >
        {items.map((result) => {
          return (
            <div className="flex" key={result.id}>
              <div style={{ flex: 1 }}>
                <Item
                  renderedInList={false}
                  result={result}
                  previewPanelId={result.id}
                  onSelect={()=> navigate({
                    pathname: `/details/${result.id}`,
                    search: `?${createSearchParams({
                      ...getParams(query),
                    })}`,
                  }, {
                    state: { showBackArrow: true }
                  })}
                  notifyIsVisible={() => {}}
                  editingList={false}
                />
              </div>
            </div>
          )
      })}
      </List> :
        <div className="flex items-center justify-center">
          <div className="text-center">
            <p className="text-gray-500">No Items</p>
          </div>
        </div>
      }
    </div>
  )
}
