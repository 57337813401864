import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"

export const DeleteConfirmation = ({ confirm, cancel }: any) => {
  return (
    <Dialog
      open={true}
      onClose={() => cancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete this content?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone. Item will also be removed from all lists, projects and from Google Drive itself.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size={'small'} autoFocus onClick={() => confirm()} variant="outlined" color="primary">Delete</Button>
        <Button size={'small'} onClick={() => cancel()} variant="contained" color="success">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
