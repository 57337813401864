import { useState, MouseEvent, Fragment } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { itemMenuOptions } from '../../models/enums/ItemMenuOptions';
import { getJWTDecoded } from '../../helpers/helpers';
import ContentDetails from '../../models/ContentDetails';
import AdultOnly from '../shared/AdultOnly';

export default function OptionsDropdown({ onModeSelected, details }: { onModeSelected: (mode: itemMenuOptions) => void, details: null | ContentDetails }) {
  const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(addAnchorEl);
  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAddAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAddAnchorEl(null);
  };

  const handleClone = () => {
    onModeSelected(itemMenuOptions.clone);
  }

  const handleEdit = () => {
    onModeSelected(itemMenuOptions.edit);
  }

  const handleCopyShareLink = () => {
    onModeSelected(itemMenuOptions.copyLink);
  }

  const handleAddToProject = () => {
    onModeSelected(itemMenuOptions.addToProject);
  }

  const isCollaborator = (userName: string) => {
    return details?.collaborators.map(collaborator => collaborator.userName).includes(userName);
  }

  const isAdult: boolean = getJWTDecoded().isAdult;
  const userName: string = getJWTDecoded().firstName + " " + getJWTDecoded().lastName;

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Options">
          <IconButton
              onClick={handleMenuClick}
              aria-controls={menuOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              sx={{ color: "white", padding: '0 0 0 0', marginLeft: '8px' }}
          >
              <MoreVertIcon fontSize="medium" sx={{ color: "gray"}}/>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={addAnchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <AdultOnly>
          <MenuItem onClick={handleClone}>
            Clone
          </MenuItem>
        </AdultOnly>
        {(isAdult || isCollaborator(userName)) &&
            <Divider />
        }
        {(isAdult || isCollaborator(userName)) &&
            <MenuItem onClick={handleEdit}>
              Edit
            </MenuItem>
        }
        <Divider />
        <MenuItem onClick={handleCopyShareLink}>
          Copy Share Link
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAddToProject}>
          Add to Project
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
