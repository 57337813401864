import PaidIcon from '@mui/icons-material/Paid';
import { Stack, Typography } from '@mui/material';

export interface ContentMetadataProps {
    free: boolean;
    pbeejOnly: boolean;
    parentResource: boolean;
    pgGuidanceReason?: string;
    isCollection?: boolean;
}

export function ContentMetadata({ free, pbeejOnly, parentResource, isCollection = false, pgGuidanceReason }: ContentMetadataProps) {
    return <div className={'metadata flex flex-wrap'}>
        <Stack direction="row" justifyContent={"space-between"} className={'w-full'}>
            {!isCollection &&
                <div className={`self-center m-2 ${free ? `!text-gray-500` : `!text-type-tag mr-5`}`}>
                    <PaidIcon /> {free ? `Free` : `Pay`}
                </div>
            }
            {parentResource &&
                <div className={'parentResource flex flew-row p-2 mr-2'}>
                    <Typography variant={'subtitle1'} className={'status h-fit pl-3 pr-3 self-center rounded-2xl p-1'} style={{ color: 'white', backgroundColor: 'red', borderRadius: '8px' }}>Parental Guidance Needed</Typography>
                </div>
            }
        </Stack>
        {parentResource && pgGuidanceReason &&
            <div className={'parentResource flex flew-row p-2 mr-2 ml-auto right-align w-3/4'}>
                <Typography variant={'subtitle1'} className={'status h-fit pl-3 pr-3 self-center rounded-2xl p-1'} style={{ textAlign: 'right' }}>{pgGuidanceReason}</Typography>
            </div>
        }
    </div>;
}
