import AlertInfo from "../models/AlertInfo";
import { AlertType } from "../models/enums/AlertType";

export interface SelectedTags {
    keywords: Set<number>,
    type: Set<number>,
    ageRange: Set<number>,
    titleSearch: string,
    freeFlag: boolean,
    pbeejOnlyFlag: boolean,
    parentResourceFlag: boolean,
    myActive: boolean,
    myWorkInProgress: boolean,
    myPrivate: boolean
}

export interface BooleanFilter {
  flagName: string,
  flagOn: boolean
}

export function convertToString(string: string | null) {
    return string === null ? '' : string;
}

function stringToNumberSet(string: string | null) {
    return new Set((string === null ? '' : string).split(',').map(value => { return Number(value) }))
}

export function stringToBoolean(string: string) {
    return (string === 'true' || string === 'True')
}

export function convertToSelectedTags(query: any) {
    let newTagForm: SelectedTags = {
        keywords: new Set(),
        type: new Set(),
        ageRange: new Set(),
        titleSearch: '',
        freeFlag: false,
        pbeejOnlyFlag: false,
        parentResourceFlag: false,
        myActive: false,
        myWorkInProgress: false,
        myPrivate: false
    };

    newTagForm['keywords'] = query.get('keywords') ? stringToNumberSet(query.get('keywords')) : new Set();
    newTagForm['type'] = query.get('type') ? stringToNumberSet(query.get('type')) : new Set();
    newTagForm['ageRange'] = query.get('ageRange') ? stringToNumberSet(query.get('ageRange') ? query.get('ageRange') : '') : new Set();
    newTagForm['titleSearch'] = query.get('titleSearch') ? convertToString(query.get('titleSearch')) : '';
    newTagForm['freeFlag'] = query.get('freeFlag') ? stringToBoolean(convertToString(query.get('freeFlag'))) : false;
    newTagForm['pbeejOnlyFlag'] = query.get('pbeejOnlyFlag') ? stringToBoolean(convertToString(query.get('pbeejOnlyFlag'))) : false;
    newTagForm['parentResourceFlag'] = query.get('parentResourceFlag') ? stringToBoolean(convertToString(query.get('parentResourceFlag'))) : false;
    newTagForm['myActive'] = query.get('myActive') ? stringToBoolean(convertToString(query.get('myActive'))) : false;
    newTagForm['myWorkInProgress'] = query.get('myWorkInProgress') ? stringToBoolean(convertToString(query.get('myWorkInProgress'))) : false;
    newTagForm['myPrivate'] = query.get('myPrivate') ? stringToBoolean(convertToString(query.get('myPrivate'))) : false;

    return newTagForm;
}

export const getParams = (query: any) => {
    let theParams: any = {}

    if (query.get('keywords')) {
        theParams['keywords'] = convertToString(query.get('keywords'));
    }
    if (query.get('type')) {
        theParams['type'] = convertToString(query.get('type'));
    }
    if (query.get('ageRange')) {
        theParams['ageRange'] = convertToString(query.get('ageRange') ? query.get('ageRange') : '');
    }
    if (query.get('titleSearch')) {
        theParams['titleSearch'] = convertToString(query.get('titleSearch'));
    }
    if (query.get('freeFlag')) {
        theParams['freeFlag'] = convertToString(query.get('freeFlag'));
    }
    if (query.get('pbeejOnlyFlag')) {
        theParams['pbeejOnlyFlag'] = convertToString(query.get('pbeejOnlyFlag'));
    }
    if (query.get('parentResourceFlag')) {
        theParams['parentResourceFlag'] = convertToString(query.get('parentResourceFlag'));
    }
    if (query.get('myActive')) {
        theParams['myActive'] = convertToString(query.get('myActive'));
    }
    if (query.get('myWorkInProgress')) {
        theParams['myWorkInProgress'] = convertToString(query.get('myWorkInProgress'));
    }
    if (query.get('myPrivate')) {
        theParams['myPrivate'] = convertToString(query.get('myPrivate'));
    }
    if (query.get('success')) {
        theParams['success'] = convertToString(query.get('success'));
    }
    if (query.get('error')) {
        theParams['error'] = convertToString(query.get('error'));
    }

    return theParams;
}

export const ReminderAlert: AlertInfo = {
  type: AlertType.Info,
  message: "We noticed you are setup and able to add content to PBEEJ but haven't yet for the last several days. It's very easy with the Add button at the top.",
  dismissable: false
}
