import { styled, alpha } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { filterDrawerWidth } from './FilterDrawer';
import { detailsDrawerWidth } from './Discover';
import { InputBase } from '@mui/material';

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'filterPanelOpen' && prop !== 'detailsOpen' && prop !== 'isMobile' })<{
    filterPanelOpen?: boolean;
    detailsOpen?: boolean;
    isMobile?: boolean;
  }>(({ theme, filterPanelOpen, detailsOpen, isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: '#FAFAFA',
    padding: theme.spacing(3),
    paddingTop: 0,
    marginRight: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!isMobile && {
      marginLeft: `-${filterDrawerWidth}px`,
      ...(filterPanelOpen && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
      ...(detailsOpen && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }),
  }));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
  }));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
    },
  }));

interface AppBarProps extends MuiAppBarProps {
    filterPanelOpen?: boolean;
    detailsOpen?: boolean;
    isMobile?: boolean;
}

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'filterPanelOpen' && prop !== 'detailsOpen' && prop !== 'isMobile',
})<AppBarProps>(({ theme, filterPanelOpen, detailsOpen, isMobile }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!isMobile && (filterPanelOpen || detailsOpen) && {
        width: `calc(100% - ${(filterPanelOpen ? filterDrawerWidth : 0) + (detailsOpen ? detailsDrawerWidth : 0)}px)`,
        marginLeft: `${(filterPanelOpen ? filterDrawerWidth : 0)}px`,
        marginRight: `${(detailsOpen ? detailsDrawerWidth : 0)}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
