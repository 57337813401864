import { Chip, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdultOnly from '../shared/AdultOnly';

const projectStatusColorMap: Record<string, "warning" | "primary" | "success" | "default" | "secondary" | "error" | "info"> = {
  "WIP": "secondary",
  "Preparing": "secondary",
  "ACT": "primary",
  "Active": "primary",
  "COM": "success",
  "Complete": "success",
  "ARC": "info", // for now the API will not return this
  "Archived": "info" // for now the API will not return this
}

const projectStatusLabelMap: Record<string, string> = {
  "WIP": "Preparing",
  "Preparing": "Preparing",
  "ACT": "In Progress",
  "Active": "In Progress",
  "COM": "Complete",
  "Complete": "Complete",
  "ARC": "Archived", // for now the API will not return this
  "Archived": "Archived" // for now the API will not return this
}

interface ProjectStatusChipProps {
  chevron: boolean;
  status: string;
}

export default function ProjectStatusChip(props: ProjectStatusChipProps) {
  return (
    <Stack direction={'row'}>
      <Chip color={projectStatusColorMap[props.status]} label={projectStatusLabelMap[props.status]} variant="outlined" size="small" />
      <AdultOnly>
        {props.chevron ? <ExpandMoreIcon /> : null}
      </AdultOnly>
    </Stack>
  )
}
