export default function openLinkInNewTab(link: string, focus: boolean=false) {
  function addProtocol(linkString: string) {
    if(linkString.startsWith("https://", 0) || linkString.startsWith("http://", 0)) {
      return linkString;
    } else {
      return "https://".concat(linkString);
    }
  }

  const tab = window.open(addProtocol(link), '_blank');

  if (tab && focus) {
    tab.focus();
  }
};
