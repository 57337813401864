import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import AlertInfo from "../../models/AlertInfo";

export const AlertSnack = ({ alertInfo, setAlert }: { alertInfo: AlertInfo, setAlert: any }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const onClose = () => {
    setAlert(undefined);
    setIsOpen(false)
  }

  return (
    <Snackbar open={isOpen} autoHideDuration={alertInfo.dismissable ? 6000 : null} onClose={() => onClose()}>
      <Alert variant="filled" severity={alertInfo.type} sx={{ textAlign: 'left', maxWidth: '400px', width: '100%' }} onClose={onClose}>
        {alertInfo.message}
      </Alert>
    </Snackbar>
  )
}
