import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"

export const MessageAcknowledgement = ({
  messageText,
  acknowledge,
}: {
  messageText: string,
  acknowledge: () => void,
}) => {
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Message for you
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {messageText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={acknowledge}
        >
            Got It!
        </Button>
      </DialogActions>
    </Dialog>
  )
}
