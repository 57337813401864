import {FormGroup, FormControlLabel, Checkbox, Stack} from '@mui/material';
import { useState } from 'react';
import { getIcon } from '../util/StatusIcons';

interface StatusFiltersProps {
    myActive: boolean,
    myWorkInProgress: boolean,
    myPrivate: boolean,
    setBooleanFlags: any
}

interface OnlyMyStuff {
  myStuff: boolean,
  myActive: boolean,
  myWorkInProgress: boolean,
  myPrivate: boolean
}

export default function StatusFilters({
      myActive,
      myWorkInProgress,
      myPrivate,
      setBooleanFlags}: StatusFiltersProps ) {

    const [onlyMyStuff, setOnlyMyStuff] = useState<OnlyMyStuff>({
      myStuff: (myActive || myWorkInProgress || myPrivate),
      myActive: myActive,
      myWorkInProgress: myWorkInProgress,
      myPrivate: myPrivate
    })

    const setSelectedStatus = (checked: string) => {
      let filters = [];
      let selectedState = onlyMyStuff;
      if (checked === 'onlyMyStuff') {
        selectedState.myActive = selectedState.myWorkInProgress = selectedState.myPrivate = !onlyMyStuff.myStuff;
        filters.push({ flagName: 'myActive', flagOn: !onlyMyStuff.myStuff });
        filters.push({ flagName: 'myWorkInProgress', flagOn: !onlyMyStuff.myStuff });
        filters.push({ flagName: 'myPrivate', flagOn: !onlyMyStuff.myStuff });
      }
      else {
        selectedState[checked as keyof OnlyMyStuff] = !selectedState[checked as keyof OnlyMyStuff];
        filters.push({ flagName: checked, flagOn: selectedState[checked as keyof OnlyMyStuff] });
      }
      selectedState.myStuff = (selectedState.myActive || selectedState.myWorkInProgress || selectedState.myPrivate);
      setOnlyMyStuff(selectedState);
      setBooleanFlags(filters);
    }

    return (
        <FormGroup sx={{display: 'inline-flex', flexDirection: 'row'}}>
            <FormControlLabel control={<Checkbox checked={onlyMyStuff.myStuff} onClick={() => setSelectedStatus('onlyMyStuff')} />} label="Only My Stuff"/>
            <div className={'flex flex-col ml-5 text-left'}>
              <FormControlLabel control={
                <Checkbox
                  checked={onlyMyStuff.myWorkInProgress}
                  onClick={() => setSelectedStatus('myWorkInProgress')}/>}
                  label={
                    <Stack direction={'row'} alignItems={'flex-start'}>
                      {getIcon("WIP")} Work In Progress
                    </Stack>
                  }
                />
              <FormControlLabel control={
                <Checkbox
                  checked={onlyMyStuff.myPrivate}
                  onClick={() => setSelectedStatus('myPrivate')} />}
                  label={
                    <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'center'}>
                      {getIcon("COM")}Private/{getIcon("SHR")}Shared
                    </Stack>
                  }
                />
              <FormControlLabel control={<Checkbox checked={onlyMyStuff.myActive} onClick={() => setSelectedStatus('myActive')} />} label="Active"/>
            </div>
        </FormGroup>
    );
}
