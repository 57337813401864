import Typography from "@mui/material/Typography";
import ContentDetails from "../../models/ContentDetails";
import Tag from "../tags/Tag";
import { convertToSelectedTags } from "../../helpers/discover";
import { useQuery } from "../../helpers/helpers";
import TagModel from "../../models/Tag";

interface KeywordsProps {
    keywordTags: ContentDetails["keywordTags"],
    keywords: TagModel[],
    setTags: (tagName: any, tags: Set<number>) => void
}

export default function KeyWords({ keywordTags, keywords, setTags }: KeywordsProps) {
    let query = useQuery();
    let selectedTags = convertToSelectedTags(query);

    return (
        <span className={"keywords flex flex-col pb-2"}>
            <Typography className={"text-keywords-tag text-left"} variant={"subtitle1"} component={"div"}>
                Keywords:
            </Typography>
            <div className={"flex flex-wrap"}>
                {keywordTags.map((keywordTag) => {
                    return (
                        <Tag
                            key={keywordTag.id}
                            editable={false}
                            tag={keywords.find((element) => element.Id === keywordTag.id)}
                            deleteTag={(): null => null}
                            clickHandler={() => setTags("keywords", selectedTags.keywords.add(keywordTag.id))}
                        />
                    );
                })}
            </div>
        </span>
    );
}