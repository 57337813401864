import { useState, Fragment } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import Tag from '../tags/Tag';
import Collaborator from '../../models/Collaborator';
import CollaboratorTag from '../../models/CollaboratorTag';
import AdultOnly from './AdultOnly';
import { getJWTDecoded } from '../../helpers/helpers';

export default function EditCollaborators({
  availableTags,
  selectedTags,
  setSelectedTags,
}: {
  availableTags: Collaborator[],
  selectedTags: CollaboratorTag[] | undefined,
  setSelectedTags: (selectedTags: CollaboratorTag[] | undefined) => void,
}) {
  const [suggestions, setSuggestions] = useState<CollaboratorTag[]>([]);

  const isAdult: boolean = getJWTDecoded().isAdult;
  const userName: string = getJWTDecoded().firstName + " " + getJWTDecoded().lastName;

  return (
    <Fragment>
      <AdultOnly>
        <Autocomplete
          value={suggestions}
          multiple
          onChange={(_event, [newValue]) => {
            const updatedValues = selectedTags?.concat(newValue);
            setSelectedTags(updatedValues);
            setSuggestions([]);
          }}
          filterOptions={(options, params) => {
            return createFilterOptions<CollaboratorTag>()(options, params);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={availableTags.filter(availableTag => {
            return !selectedTags?.some(selectedTag => selectedTag.userId === availableTag.userId);
          })}
          getOptionLabel={(option) => option.userName}
          renderOption={(props, option) => <li {...props} key={option.userId}>{option.userName}</li>}
          sx={{ width: 300 }}
          renderInput={(params) => {
            return (
              <TextField {...params} label="Add People" />
            )
          }}
        />
      </AdultOnly>

      <div className='flex flex-wrap mt-2'>
        {selectedTags?.map(value => (
          <Tag
            key={value.userId}
            editable={isAdult && value.userName !== userName}
            tag={{
              TagType: 'Collaborator',
              Id: value.userId,
              Text: value.userName
            }}
            deleteTag={isAdult ? () => {
              const updatedValues = selectedTags?.filter(v => v.userId !== value.userId);
              setSelectedTags(updatedValues);
            } : () => {}}
          />
        ))}
      </div>
    </Fragment>
  );
};
