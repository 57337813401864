import Typography from "@mui/material/Typography";
import Collaborator from "../../models/Collaborator";
import Tag from "../tags/Tag";

interface CollaboratorsProps {
    collaborators: Collaborator[]
}

export default function Collaborators({ collaborators }: CollaboratorsProps) {

    return (
        <span className={"keywords flex flex-col pb-2"}>
            <Typography className={"text-collaborators-tag text-left"} variant={"subtitle1"} component={"div"}>
                Collaborators:
            </Typography>
            <div className={"flex flex-wrap"}>
                {collaborators.map((collaborator) => {
                    return (
                        <Tag
                            key={collaborator.userId}
                            editable={false}
                            tag={{
                                TagType: "Collaborator",
                                Id: collaborator.userId,
                                Text: collaborator.userName,
                            }}
                            deleteTag={(): null => null}
                        />
                    );
                })}
            </div>
        </span>
    );
}