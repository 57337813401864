import { axiosInstance } from './helpers';
import ProjectDetails from '../models/ProjectDetails';

export interface ProjectMetadata {
    id: number;
    title: string;
    description: string;
    status: string;
    createdDate: string;
}

export interface ProjectSearchRequest {
  searchText: string;
}

const statusSortOrderMap: Record<string, number> = {
  WIP: 1,
  ACT: 2,
  COM: 3,
  ARC: 4,
}

const searchProjects = async (param: ProjectSearchRequest): Promise<ProjectMetadata[]> => {
  try {
    const response = await axiosInstance.get<ProjectMetadata[]>(`/project/list?searchText=${param.searchText}`);

    // sort by status (Preparing then In Progress then Completed then Archived) and creation date
    response.data.sort((a: ProjectMetadata, b: ProjectMetadata) => {
      if (statusSortOrderMap[a.status] < statusSortOrderMap[b.status]) {
        return -1;
      } else if (statusSortOrderMap[a.status] === statusSortOrderMap[b.status] && a.createdDate < b.createdDate) {
        return 1;
      }
      return 0;
    })

    return response.data;
  } catch (exception) {
    throw new Error(`ERROR received from /project/list: ${exception}\n`);
  }
}

const getProjectDetails = async (projectId: string): Promise<ProjectDetails> => {
  try {
    const response = await axiosInstance.get(`/project?id=${projectId}`);

    for (const content of response.data.projectContent) {
      if (content.projectContentType === "Resource") {
        content.projectContentType = "RES"
      } else if (content.projectContentType === "Output") {
        content.projectContentType = "OUT"
      }
    }

    return response.data;
  } catch (ex) {
    throw new Error(`ERROR received from GET /project?id=${projectId}: ${ex}`)
  }
}

const createProject = async (title: string, description: string, status: string): Promise<number> => {
  try {
    const response = await axiosInstance.post('/project', {
      title,
      description,
      status
    });
    return response.data.projectId;
  } catch (ex) {
    throw new Error(`ERROR received from POST /project: ${ex}`)
  }
}

const updateProject = async (project: ProjectDetails): Promise<number> => {
  try {
    const response = await axiosInstance.put('/project', {
      id: project.id,
      title: project.title,
      description: project.description,
      status: project.status,
    });
    return response.data.projectId;
  } catch (ex) {
    throw new Error(`ERROR received from PUT /project: ${ex}`)
  }
}

const addProjectMember = async (projectId: number, memberId: number, googleAccessToken: string): Promise<void> => {
  try {
    await axiosInstance.post('/project/member', {
      projectId,
      memberId,
      googleAccessToken
    });
  } catch (ex) {
    throw new Error(`ERROR received from POST /project/member: ${ex}`)
  }
}

const removeProjectMember = async (projectId: number, memberId: number, googleAccessToken: string): Promise<void> => {
  try {
    await axiosInstance.delete('/project/member', {
      data: {
        projectId,
        memberId,
        googleAccessToken
      }
    });
  } catch (ex) {
    throw new Error(`ERROR received from DELETE /project/member: ${ex}`)
  }
}

const addProjectContent = async (projectId: number, contentId: number, resourceType: string, googleAccessToken: string): Promise<void> => {
  try {
    await axiosInstance.post('/project/content', {
      projectId,
      contentId,
      resourceType,
      googleAccessToken,
    });
  } catch (ex) {
    throw new Error(`ERROR received from POST /project/content: ${ex}`)
  }
}

const removeProjectContent = async (projectId: number, contentId: number, resourceType: string): Promise<void> => {
  try {
    await axiosInstance.delete('/project/content', {
      data: {
        projectId,
        contentId,
        resourceType,
      }
    });
  } catch (ex) {
    throw new Error(`ERROR received from DELETE /project/content: ${ex}`)
  }
}

const deleteProject = async (projectId: number): Promise<void> => {
  try {
    await axiosInstance.delete(`/project?id=${projectId}`);
  } catch (ex) {
    throw new Error(`ERROR received from DELETE /project?id=${projectId}: ${ex}`)
  }
}

export {
  searchProjects,
  getProjectDetails,
  createProject,
  updateProject,
  addProjectMember,
  removeProjectMember,
  addProjectContent,
  removeProjectContent,
  deleteProject
}
