import { FunctionComponent, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import AuthContextValue from '../models/AuthContextValue';
import { Navigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const AuthProtection: FunctionComponent = (props) => {
  const { isAuthorized } = useContext<AuthContextValue>(AuthContext);
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to="/login" replace={true} state={{ prevUrl: location.pathname }}/>
  }

  if (props.children) {
    return <>{props.children}</>;
  }

  return <Outlet />
}

export default AuthProtection;
