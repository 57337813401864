import {
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Tag from '../../models/Tag';

export default function AgeRange({ allTags, selectedTags, setTags }: {
  allTags: Tag[] | undefined,
  selectedTags: Set<number>,
  setTags: Function
}) {

  function toggleTag(tagId: number) {
    let temptags: Set<number> = selectedTags;
    if (selectedTags.has(tagId)) {
      temptags.delete(tagId)
    } else {
      temptags.add(tagId);
    }
    setTags('ageRange', temptags);
  }

  return (
    <div className={'age-range flex flex-col text-left item-start w-full pl-2 pr-2'}>
      <div className={'w-full h-auto grid grid-rows-4 grid-flow-col pl-1'}>
        {allTags?.map((tag) => {
          return <FormControlLabel
            control={
              <Checkbox
                checked={selectedTags.has(tag.Id)}
                onClick={() => toggleTag(tag.Id)}
              />}
            label={tag.Text}
            key={tag.Id}
            value={tag.Id}
          />
        })}
      </div>
    </div>
  );
}
