import GoogleClientBase from "./GoogleClientBase";

class Document extends GoogleClientBase {
  getLink(fileId: string): string {
    return `https://docs.google.com/document/d/${fileId}`;
  }

  async create(title: string): Promise<string> {
    const response = await gapi.client.docs.documents.create({
      resource: {
        title,
      },
    });

    const { documentId } = response.result;

    if (!documentId) {
      throw new Error("Google Document created with empty ID.");
    }

    return documentId;
  }
};

export default new Document();
